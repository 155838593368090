import React, {FC} from "react";
import * as fa from "@fortawesome/free-solid-svg-icons"
import {useApi} from "../api/APIContext";
import {ModalFooter} from "@traas/lib/src/components/layout/ModalProvider";
import { Input } from "@traas/lib/src/components/form/Input";
import {ValidationError} from "../api/api";
import {Color, QuantityType, TASK_QUANTITY_TYPE_OTHER} from "../api/dto";
import {ObjectAutocomplete} from "@traas/lib/src/components/form/Autocomplete";
import {Button, IconButton} from "@traas/lib/src/components/form/Button";
import {faAdd, faMinusCircle} from "@fortawesome/free-solid-svg-icons";
import {Toggle} from "@traas/lib/src/components/form/Toggle";


export const AddOrEditQuantityTypeModal: FC<{quantityType?: QuantityType}> = (props) => {
  const {addQuantityType, editQuantityType, colors} = useApi()
  const isRestricted = props.quantityType?.name === TASK_QUANTITY_TYPE_OTHER
  const [name, setName] = React.useState(props.quantityType?.name ?? "")

  const [color, setColor] = React.useState<Color>(colors.find(c => c.id === props.quantityType?.defaultColor) ?? {id: 'null', name: 'Geen standaardkleur', color: 'slate'} as Color)
  const [hideArea, setHideArea] = React.useState<boolean>(props.quantityType?.hideArea ?? false)
  const [descriptionTemplate, setDescriptionTemplate] = React.useState<string[]>(props.quantityType?.descriptionTemplate ?? [""])
  const [errors, setErrors] = React.useState<string[]>([])
  const confirm = async () => {
    console.log(color)
    const addOrEdit = (name: string, enabled: boolean, defaultColor: string|null, descriptionTemplate: string[], hideArea: boolean) => props.quantityType?.id ? editQuantityType(props.quantityType?.id, name, enabled, defaultColor, descriptionTemplate, hideArea) : addQuantityType(name, enabled, defaultColor, descriptionTemplate, hideArea)
    await addOrEdit(name, props.quantityType?.enabled ?? true, color.id === 'null' ? null : color.id, descriptionTemplate, hideArea)
      .catch(e => {
        if (e instanceof ValidationError) {
          setErrors(Object.values(e.errors).flat())
        }
        throw e
      })
  }

  const options = [
    {id: 'null', name: 'Geen standaardkleur', color: 'slate'} as Color,
    ...colors,
  ]

  return <form>
    <div className={"space-y-2"}>
      {!isRestricted && <Input label={'Naam'} value={name} onChange={setName} type={'text'}/>}
      <ObjectAutocomplete label={'Standaardkleur'} options={options} value={color} onChange={setColor}
                          displayItem={(c) => c.name} idProperty={'id'}/>
      <Toggle label={"Verberg m²"} value={hideArea} onChange={setHideArea}/>
      {descriptionTemplate.map((name, i, names) => {
        return <div key={i} className={"flex items-end space-x-2"}>
          <Input label={'Omschrijving (sjabloon)'} value={name} onChange={(name) => setDescriptionTemplate(old => {
            const newNames = [...old]
            newNames[i] = name
            return newNames
          })} type={'text'}/>
          {names.length > 1 && <IconButton type={'secondary'} size={'md'} icon={faMinusCircle}
                                           onClick={() => setDescriptionTemplate(old => old.filter((_, j) => j !== i))}/>}
        </div>
      })}
      <Button type={'secondary'} size={'sm'} icon={faAdd} text={'Extra sjabloon toevoegen'}
              onClick={() => setDescriptionTemplate(old => [...old, ""])}/>
      {errors.length > 0 && <div className={"text-red-600 text-sm"}>
        {errors.map((e, i) => <p key={i}>{e}</p>)}
      </div>}
      <ModalFooter icon={fa.faPlus} text={"Opslaan"} onSubmit={confirm}/>
    </div>
  </form>
}
