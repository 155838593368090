import {Project, Task} from "../../api/dto";
import React, {FC, useState} from "react";
import {usePlanner} from "../PlannerContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faChevronDown, faChevronUp, faEllipsisVertical, faEye, faEyeSlash,
  faGrip,
  faHashtag,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import {useThemes} from "../util/theming";
import {useApi} from "../../api/APIContext";
import {TaskContextMenu} from "./TaskContextMenu";
import moment from "moment";
import {useModal} from "@traas/lib/src/components/layout/ModalProvider";
import {AddTaskModal} from "../../modals/AddTaskModal";
import {useTaskTypes} from "../hooks/useTaskTypes";
import {IconButton} from "@traas/lib/src/components/form/Button";
import {ProjectDetailsModal} from "../../modals/ProjectDetailsModal";

export const ProjectCard: FC<{projectId: string, taskFilter: "all"|"unplanned"|"planned"}> = ({projectId, taskFilter}) => {
  const {projectMap, projectTasksMap, setDay, focusTask} = usePlanner()
  const project = projectMap.get(projectId)
  const tasks = projectTasksMap.get(projectId) ?? []
  const projectModal = useModal({title: `Project #${project?.projectNumber}`, body: <ProjectDetailsModal goToDay={(day) => setDay(day)} focusTask={(task) => focusTask(task)} project={project!} />, size: '2xl'})

  if (!project) return <></>
  return <div className={"my-2 border border-slate-300 rounded-lg mx-2 py-1 px-2"}>
    <div className={"flex items-center"}>
      <FontAwesomeIcon className={"text-slate-500 text-xs"} icon={faHashtag} />
      <span className={"font-medium flex-1 text-sm font-mono text-slate-600 ml-1"}>{project.projectNumber}</span>
      <span className={"text-[10px] uppercase font-bold px-1 rounded-sm bg-blue-50 text-blue-800"}>{project.status}</span>
    </div>
    <button className={'rounded flex items-stretch w-full hover:bg-slate-100 cursor-pointer text-sm px-2'} onClick={() => projectModal.open()}>
      <div className={"flex-1 text-left"}>
        <div className={"text-sm my-1 text-blue-900 font-medium leading-tight"}>{project.description}</div>
        <div className={"text-sm my-1 text-slate-600 leading-tight"}>{project.deliveryDetails.city}</div>
      </div>
      <div className={'ml-2 mt-1'}>
        <FontAwesomeIcon icon={faEllipsisVertical} className={"text-blue-900"}/>
      </div>
    </button>
    <TaskIndicator tasks={tasks} project={project} taskFilter={taskFilter}/>
  </div>
}

const TaskIndicator: FC<{project: Project, tasks: Task[], taskFilter: "all"|"unplanned"|"planned"}> = ({tasks, project, taskFilter}) => {
  const [expanded, setExpanded] = useState(false)
  if (tasks.length === 0) {
    return <TaskAddForm projectId={project.id} />
  }
  if (!expanded) {
    const tasksPlanned = tasks.filter(t => t.startAt !== null)
    const tasksInvoiced = tasks.filter(t => t.isInvoiced)
    return <button
      className={"text-xs font-medium text-slate-900 hover:bg-slate-100 rounded w-full mt-2 mb-1 px-2 py-1"}
      onClick={() => setExpanded(true)}>
      <div className={"flex items-center justify-between"}>
        <span className={""}>{tasksPlanned.length} / {tasks.length} taken ingepland</span>
        <span className={"text=[10px] text-slate-600"}><FontAwesomeIcon icon={faChevronDown} className={"mr-1"}/>DETAILS</span>
      </div>
      <div className={"h-2 mt-1 w-full rounded-full bg-slate-200 relative"}>
        <div className={'bg-blue-800 h-full absolute top-0 left-0 rounded-full'}
             style={{width: `${tasksPlanned.length / tasks.length * 95 + 5}%`}}></div>
      </div>
      <div className={"mt-2 flex items-center justify-between"}>
        <span className={""}>{tasksInvoiced.length} / {tasks.length} taken gefactureerd</span>
      </div>
      <div className={"h-2 mt-1 w-full rounded-full bg-slate-200 relative"}>
        <div className={'bg-emerald-700 h-full absolute top-0 left-0 rounded-full'}
             style={{width: `${tasksInvoiced.length / tasks.length * 95 + 5}%`}}></div>
      </div>
    </button>
  }

  return <div className={""}>
    <TaskList dragMode={"datePreview"} tasks={tasks} taskFilter={taskFilter} showProjects={false} />
    <TaskAddForm projectId={project.id}/>
    <button className={"text-xs font-medium text-slate-900 hover:bg-slate-100 rounded w-full mt-2 mb-1 px-2 py-1"}
            onClick={() => setExpanded(false)}>
    <FontAwesomeIcon icon={faChevronUp} className={"mr-1"} />Minder tonen
    </button>
  </div>
}

export const TaskList: FC<{tasks: Task[], taskFilter: "all"|"unplanned"|"planned", dragMode: "datePreview"|"always", showProjects: boolean}> = ({tasks, taskFilter, dragMode, showProjects}) => {
  const {onMouseDown, floatingTask, focusTask} = usePlanner()
  const {getTheme} = useThemes()
  const {getTaskName, getTaskColor} = useTaskTypes()
  const {projects} = useApi()
  const filteredTasks = tasks.filter((t) => {
    if (taskFilter === "all") return true
    if (taskFilter === "unplanned") return t.startAt === null
    if (taskFilter === "planned") return t.startAt !== null
    return false
  }).sort((a,b) => {
    if (a.startAt === null && b.startAt === null) return 0
    if (a.startAt === null) return -1
    if (b.startAt === null) return 1
    return b.startAt.getTime() - a.startAt.getTime()
  })
  return <>
    {filteredTasks.length === 0 && tasks.length > 0 && <div className={"text-xs text-slate-600 text-center my-2 bg-slate-50 rounded"}>{tasks.length - filteredTasks.length} taken verborgen door filter</div>}
    {filteredTasks.map((task, i) => {
      const theme = getTheme(getTaskColor(task) ?? undefined, task.isInvoiced || task.isVoided)
      const isFloating = floatingTask?.id === task.id
      const project = projects.find(p => p.id === task.projectId)
      return <div key={i} className={`${isFloating ? 'bg-gray-200' : theme.bg} my-2 rounded ${isFloating ? 'text-gray-200' : 'text-white'} text-sm flex items-stretch`}>
        <TaskContextMenu task={task} className={'flex items-stretch justify-start flex-1'} onLeftMouseDown={() => {}}>
          {task.startAt !== null && dragMode === "datePreview" ? <>
            <button className={`w-8 -mr-2 flex flex-col items-center justify-center hover:bg-[#0002]`} onClick={() => focusTask(task)}>
              <span
                className={"h-3 w-4 flex items-center justify-center text-[11px] text-[#fffd]"}>{moment(task.startAt!).year()}</span>
              <span
                className={"h-3 flex items-center justify-center text-[12px] font-medium text-white"}><span className={"mr-[2px] text-[#fffa]"}>w</span>{moment(task.startAt!).week()}</span>
            </button>
          </> : <>
            <button
              className={`${isFloating ? '' : 'text-white'} ${isFloating ? '' : 'bg-[#0003]'} text-base flex items-center justify-center w-6 cursor-move`} onMouseDown={() => onMouseDown(task.id, "float")}><FontAwesomeIcon icon={faGrip} rotation={90} /></button>
          </>}
          <div className={`flex-1 text-left py-2 px-3`}>
            {(task.isInvoiced || task.isVoided)&&<span className={"font-bold text-white"}>{task.isInvoiced ? <>&euro;&nbsp;</> : (task.isVoided ? <FontAwesomeIcon icon={faEyeSlash} className={'text-xs opacity-60 mr-1'}/> : '')}</span>}{getTaskName(task)}
            {project && showProjects && <p className={`${theme.lightText} text-xs overflow-ellipsis -mx-1 px-1 ${theme.bg} rounded`}>{(task.isInvoiced || task.isVoided)&&<span className={`font-bold ${theme.text}`}>{task.isInvoiced ? <>&euro;&nbsp;</> : (task.isVoided ? <FontAwesomeIcon icon={faEyeSlash} className={'text-xs opacity-60 mr-1'}/> : '')}</span>}#{project.projectNumber}, {project.deliveryDetails.city} - {project.description}</p>}
          </div>
        </TaskContextMenu>
      </div>
    })}
  </>
}

export const TaskAddForm: FC<{projectId: string}> = ({projectId}) => {
  const addTaskModal = useModal({title: "Taak toevoegen", body: <AddTaskModal projectId={projectId} onSubmit={async (task) => {}} />})
  return <button className={"text-sm font-medium text-blue-900 bg-blue-50 hover:bg-blue-100 rounded w-full mt-2 mb-1 h-7 flex items-center justify-center"} onClick={() => addTaskModal.open()}>
    <FontAwesomeIcon icon={faPlus} className={"text-xs"} />
    <span className={"ml-1"}>Voeg taak toe</span>
  </button>
}