import React, {FC, useCallback, useContext, useEffect, useState} from "react";
import {EmployeeLocation} from "../api/dto";
import {useApiCall} from "../api/api";
import {useFetchedResource} from "../api/APIContext";

interface LiveLocationContextType {
  currentLocations: { [employeeId: string]: EmployeeLocation }
  updateEmployeeLocation: (location: EmployeeLocation) => void
}
export const LiveLocationContext = React.createContext<LiveLocationContextType>({} as LiveLocationContextType)

export const LiveLocationContextProvider: FC<{children: React.ReactNode}> = ({children}) => {
  const {getEmployeeLocations} = useApiCall()
  const history = useFetchedResource<EmployeeLocation[]>(() => getEmployeeLocations())
  const [currentLocations, setCurrentLocations] = useState<{ [employeeId: string]: EmployeeLocation }>({})
  useEffect(() => {
    history.reload(undefined)
  }, [])
  useEffect(() => {
    const historyMap = history.resource?.reduce((map, location) => {
      if (! map[location.employeeId]) {
        return {
          ...map,
          [location.employeeId]: location
        }
      } else {
        if (map[location.employeeId].createdAt.getTime() < location.createdAt.getTime()) {
          return {
            ...map,
            [location.employeeId]: location
          }
        } else {
          return map
        }
      }
    }, {} as { [employeeId: string]: EmployeeLocation })
    setCurrentLocations(oldLocations => ({...oldLocations, ...historyMap}))
  }, [history.resource]);

  const updateEmployeeLocation = useCallback((location: EmployeeLocation) => {
    setCurrentLocations(old => ({...old, [location.employeeId]: location}))
  }, [])

  return <LiveLocationContext.Provider value={{
    currentLocations,
    updateEmployeeLocation,
  }}>{children}</LiveLocationContext.Provider>
}

export const useLiveLocations = () => {
  return useContext(LiveLocationContext)
}