import React, {FC, useCallback, useState} from "react";
import * as fa from "@fortawesome/free-solid-svg-icons"
import {Task} from "../api/dto";
import {useApi} from "../api/APIContext";
import {ModalFooter} from "@traas/lib/src/components/layout/ModalProvider";
import {ValidationError, ErrorBag} from "../api/api";
import {InputErrors} from "../components/InputErrors";


export const DeleteTaskModal: FC<{task: Task}> = (props) => {
  const {deleteTask} = useApi()
  const [errors, setErrors] = useState<ErrorBag>({})
  console.log(errors)
  const confirm = useCallback(async () => {
    const response = await deleteTask(props.task).catch(error => {
      if (error instanceof ValidationError) {
        setErrors(error.errors)
        throw new Error('Validation error')
      }
      throw error
    })
  }, [props.task])

  return <form>
    <p className={""}>
      <strong>Let op!</strong> Je staat op het punt om de taak <strong>{props.task.description}</strong> te verwijderen.
    </p>
    {errors ?
      <span className={'text-red-600'}>{Object.values(errors).flat().join(', ')}</span>
      : null}

    <ModalFooter icon={fa.faTimes} text={"Verwijderen"} onSubmit={confirm} />
  </form>
}
