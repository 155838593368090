import React, {FC, useEffect, useMemo, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faCalendarCheck,
  faCheck,
  faChevronLeft,
  faChevronRight,
  faCircleNotch,
  faSave
} from "@fortawesome/free-solid-svg-icons";
import {usePlanner} from "./PlannerContext";
import * as fa from "@fortawesome/free-solid-svg-icons";
import {ProjectCard, TaskAddForm, TaskList} from "./components/ProjectCard";
import {useApi} from "../api/APIContext";
import {usePersistentState} from "@traas/lib/src/util/usePersistentState";
import {Button} from "@traas/lib/src/components/form/Button";
import moment from "moment";

export const Drawer: FC = () => {
  const {setArea, day} = usePlanner()
  const [search, setSearch] = useState("")
  const {loading, projects, tasks} = useApi()

  const [pagination, setPagination] = useState(100)
  const [editingFilterSettings, setEditingFilterSettings] = useState(false)
  const uniqueStates = useMemo(() => {
    const states = new Set<string>()
    projects.forEach(p => states.add(p.status))
    return Array.from(states)
  }, [projects])
  const [order, setOrder] = useState<'ASC'|'DESC'>('DESC')
  const [tab, setTab] = usePersistentState<"projects"|"custom_tasks">("tab", "projects")
  const [taskFilter, setTaskFilter] = usePersistentState<"all"|"planned"|"unplanned">("taskFilter", "all")
  const [showOldCustomTasks, setShowOldCustomTasks] = usePersistentState<boolean>("showOldCustomTasks", false)
  const [skipStates, setSkipStates] = usePersistentState<string[]>("skipStates", [])
  const filtersEnabled = useMemo(() => skipStates.filter(s => uniqueStates.includes(s)).length > 0 || taskFilter !== "all", [skipStates, uniqueStates, taskFilter])
  const drawerProjects = useMemo(() => {
    return projects
      .sort((a, b) => order === 'DESC' ? b.projectNumber.localeCompare(a.projectNumber): a.projectNumber.localeCompare(b.projectNumber)) // Sort by project number DESC
      .filter(p => !skipStates.includes(p.status))
      .filter(p => p.projectNumber.toLowerCase().includes(search.toLowerCase()) || p.description.toLowerCase().includes(search.toLowerCase()));
  }, [search, projects, skipStates, order])

  useEffect(() => {
    setPagination(100)
  }, [tab]);
  const [isCollapsed, setIsCollapsed] = usePersistentState<boolean>('collapse_drawer', false)

  const customTasks = useMemo(() => {
    return tasks.filter(t => t.projectId === "0")
      .filter(t => showOldCustomTasks || t.startAt === null || moment(t.startAt).isAfter(moment(day).subtract(30, 'day')))
      .filter(t => t.description.toLowerCase().includes(search.toLowerCase()))
  }, [tasks, showOldCustomTasks, day, search])

  return <div className={`hidden lg:block ${isCollapsed ? 'w-8' : 'w-72'} border-l border-slate-200 bg-white`} onMouseEnter={() => setArea('drawer')}
              onMouseLeave={() => setArea('world')}>
    {!isCollapsed && <>
      {/* Controls */}
      <div className={"h-32 border-b pb-2 border-slate-200 flex flex-col justify-between"}>
        <div className={"flex items-stretch"}>
          <button
            className={`flex-1 py-3 text-sm uppercase font-medium border-b-4 ${tab === "projects" ? "bg-blue-50 border-blue-800" : "border-slate-50"}`}
            onClick={() => setTab("projects")}>Projecten
          </button>
          <button
            className={`flex-1 py-3 text-sm uppercase font-medium border-b-4 ${tab === "custom_tasks" ? "bg-blue-50 border-blue-800" : "border-slate-50"}`}
            onClick={() => setTab("custom_tasks")}>Eigen taken
          </button>
        </div>
        <div
          className={"text-xs px-3 text-center text-slate-500"}>{tab === "projects" ? `${drawerProjects.length}/${projects.length} projecten zichtbaar` : ``}</div>
        <div className={"flex px-3"}>
          <label className="bg-slate-100 h-8 text-sm w-full flex items-center rounded-lg focus-within:bg-slate-200">
            <FontAwesomeIcon icon={fa.faSearch} className={"text-gray-400 mx-2"}/>
            <input type="text" className={"text-sm flex-1 h-full outline-0 bg-transparent"} placeholder={"Zoeken..."}
                   value={search} onChange={e => setSearch(e.target.value)}/>
          </label>
          <button className={"ml-3 relative"} onClick={() => setOrder(x => x === 'ASC' ? 'DESC' : 'ASC')}>
            <FontAwesomeIcon icon={order === 'ASC' ? fa.faArrowUp19 : fa.faArrowDown91}
                             className={"text-gray-400 w-4 hover:text-blue-900"}/>
          </button>
          <button className={"ml-3 relative"} onClick={() => setEditingFilterSettings(x => !x)}>
            <FontAwesomeIcon icon={editingFilterSettings ? fa.faTimes : fa.faFilter}
                             className={"text-gray-400 w-4 hover:text-blue-900"}/>
            {filtersEnabled && !editingFilterSettings &&
              <div className={"absolute top-0 -right-2 h-3 w-3 border-2 border-white rounded-full bg-blue-600"}/>}
          </button>
        </div>
      </div>
      {loading ? <>
        <div className={"flex flex-col items-center justify-center px-3 py-2 h-32"}>
          <FontAwesomeIcon icon={faCircleNotch} spin={true} className={"text-2xl text-gray-300"}/>
          <p className={"text-sm mt-2 text-gray-700"}>
            Projecten laden...
          </p>
        </div>
      </> : <>
        {editingFilterSettings ? <div className={"p-3"}>
          <div className={"flex flex-col items-stretch"}>
            <h3 className={"font-medium mb-3"}>Project filters</h3>
            {uniqueStates.map((state, i) => {
              const selected = !skipStates.includes(state)
              return <label key={i}
                            className={`flex items-center cursor-pointer h-8 text-sm rounded mb-3 border ${selected ? 'bg-blue-50 border-blue-700 text-blue-900' : 'border-slate-200'}`}>
                <input type="checkbox" className={"mx-2 checked:bg-blue-900"} checked={selected} onChange={() => {
                  if (skipStates.includes(state)) {
                    setSkipStates(skipStates.filter(s => s !== state))
                  } else {
                    setSkipStates([...skipStates, state])
                  }
                }}/>
                <span>{state}</span>
              </label>
            })}
            <h3 className={"font-medium mb-3"}>Taak filters</h3>
            <label
              className={`flex items-center cursor-pointer h-8 text-sm rounded mb-3 border ${taskFilter === "all" ? 'bg-blue-50 border-blue-700 text-blue-900' : 'border-slate-200'}`}>
              <input type="radio" className={"mx-2 checked:bg-blue-900"} checked={taskFilter === "all"}
                     onChange={() => setTaskFilter("all")}/>
              <span>Toon alle taken</span>
            </label>
            <label
              className={`flex items-center cursor-pointer h-8 text-sm rounded mb-3 border ${taskFilter === "unplanned" ? 'bg-blue-50 border-blue-700 text-blue-900' : 'border-slate-200'}`}>
              <input type="radio" className={"mx-2 checked:bg-blue-900"} checked={taskFilter === "unplanned"}
                     onChange={() => setTaskFilter("unplanned")}/>
              <span>Toon ongeplande taken</span>
            </label>
            <label
              className={`flex items-center cursor-pointer h-8 text-sm rounded mb-3 border ${taskFilter === "planned" ? 'bg-blue-50 border-blue-700 text-blue-900' : 'border-slate-200'}`}>
              <input type="radio" className={"mx-2 checked:bg-blue-900"} checked={taskFilter === "planned"}
                     onChange={() => setTaskFilter("planned")}/>
              <span>Toon geplande taken</span>
            </label>
            <label
              className={`flex items-center cursor-pointer h-8 text-sm rounded mb-3 border ${showOldCustomTasks ? 'bg-blue-50 border-blue-700 text-blue-900' : 'border-slate-200'}`}>
              <input type="checkbox" className={"mx-2 checked:bg-blue-900"} checked={showOldCustomTasks}
                     onChange={() => {
                       setShowOldCustomTasks(!showOldCustomTasks)
                     }}/>
              <span>Toon ook oude taken</span>
            </label>
            <Button type={'primary'} size={'sm'} text={'Filters opslaan'} icon={faSave}
                    onClick={() => setEditingFilterSettings(false)}/>
          </div>
        </div> : <>
          {tab === "projects" ? <>
            {drawerProjects.length === 0 ? <>
              <div className={"flex flex-col items-center justify-center px-3 py-2 h-32"}>
                <FontAwesomeIcon icon={faCalendarCheck} className={"text-2xl text-gray-300"}/>
                <p className={"text-sm mt-2 text-gray-700"}>
                  Alle projecten zijn ingepland.
                </p>
              </div>
            </> : <div className={"overflow-y-scroll h-[calc(100vh-15rem)]"}>
              {drawerProjects.slice(0, pagination).map((project, i) => {
                return <ProjectCard projectId={project.id} taskFilter={taskFilter} key={i}/>
              })}
              {pagination < drawerProjects.length && <div className={"flex justify-center items-center my-2"}>
                <button className={"py-4 w-full text-center hover:bg-slate-50 text-blue-500 font-medium"}
                        onClick={() => setPagination(pagination + Math.min(100, drawerProjects.length - pagination))}>Toon
                  volgende {Math.min(100, drawerProjects.length - pagination)}</button>
              </div>}
            </div>}
          </> : <>
            {customTasks.length === 0 ? <>
              <div className={"flex flex-col items-center justify-center px-3 py-2 h-32"}>
                <FontAwesomeIcon icon={faCheck} className={"text-2xl text-gray-300"}/>
                <p className={"text-sm mt-2 text-gray-700"}>
                  Alle taken zijn ingepland.
                </p>
              </div>
              <div className={"px-3"}>
                <TaskAddForm projectId={"0"}/>
              </div>
            </> : <div className={"overflow-y-scroll h-[calc(100vh-15rem)]"}>
              <div className={"px-3"}>
                <TaskAddForm projectId={"0"}/>
                {taskFilter === "all" && <div
                  className={"border border-blue-300 py-2 bg-blue-50 text-xs text-slate-600 text-center my-2 rounded"}>
                  <div>{customTasks.length} taken zichtbaar</div>
                  <button onClick={() => setTaskFilter('unplanned')} className={'text-blue-800 underline'}>verberg
                    ingeplande taken
                  </button>
                </div>}
                <TaskList dragMode={"datePreview"} tasks={customTasks} taskFilter={taskFilter} showProjects={false} />
                {taskFilter === "unplanned" && <div
                  className={"border border-blue-300 py-2 bg-blue-50 text-xs text-slate-600 text-center my-2 rounded"}>
                  <div>{customTasks.filter(t => t.startAt !== null).length} taken verborgen</div>
                  <button onClick={() => setTaskFilter('all')} className={'text-blue-800 underline'}>toon alle taken
                  </button>
                </div>}
                <TaskAddForm projectId={"0"}/>
              </div>
            </div>}
          </>}

        </>}
      </>}
    </>}
    <button className={`hover:bg-gray-50 ${isCollapsed ? 'h-full' : 'h-12'} w-full`} onClick={() => setIsCollapsed(x => !x)}>
      <FontAwesomeIcon icon={isCollapsed ? faChevronLeft : faChevronRight}/>
    </button>
  </div>
}
