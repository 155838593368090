import React, {FC} from "react";
import {useApi} from "../api/APIContext";
import {useModalControls} from "@traas/lib/src/components/layout/ModalProvider";
import {ValidationError} from "../api/api";
import {Task} from "../api/dto";
import {useThemes} from "../planning/util/theming";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleNotch, faPaintRoller} from "@fortawesome/free-solid-svg-icons";
import {useTaskTypes} from "../planning/hooks/useTaskTypes";


export const ChangeTaskColorModal: FC<{task: Task}> = (props) => {
  const {updateTaskColor} = useApi()
  const {getTheme, getThemes} = useThemes()
  const [errors, setErrors] = React.useState<string[]>([])
  const {close} = useModalControls()
  const {getTaskColor} = useTaskTypes()
  const theme = getTheme(getTaskColor(props.task) ?? undefined)
  const color = theme.color
  const saveColor = async (color: string) => {
    await updateTaskColor(props.task, color)
      .catch(e => {
        if (e instanceof ValidationError) {
          setErrors(Object.values(e.errors).flat())
        }
        throw e
      })
    close()
  }


  return <form>
    <div className={"space-y-2"}>
      {props.task.color === null &&
        <div className={"border border-blue-400 rounded bg-blue-50 text-blue-900 text-sm flex items-center p-2"}>
          <FontAwesomeIcon icon={faPaintRoller} className={'mx-3 my-3'}/>
          <div>
            <h2 className={'font-semibold'}>Automatische kleur</h2>
            <p>Deze taak is automatisch <strong>{theme.name.toLowerCase()}</strong> vanwege het type taak.</p>
          </div>
        </div>}
      <div className={"grid grid-cols-4 gap-2 pb-3"}>
        {getThemes().map((c, i) => {
          const theme = getTheme(c)
          return <ColorButton key={i} theme={theme} color={c} onClick={saveColor} selected={color === c}/>
        })}
      </div>
      {errors.length > 0 && <div className={"text-red-600 text-sm"}>
        {errors.map((e, i) => <p key={i}>{e}</p>)}
      </div>}
    </div>
  </form>
}

const ColorButton: FC<{
  color: string,
  theme: { bg: string, name: string, border: string },
  onClick: (color: string) => Promise<void>,
  selected: boolean
}> = (props) => {
  const [saving, setIsSaving] = React.useState(false)
  const saveColor = async (c: string) => {
    setIsSaving(true)
    await props.onClick(c)
      .finally(() => setIsSaving(false))
  }
  return <button type={"button"}
    className={`py-4 flex flex-col rounded-lg items-center justify-center border ${props.selected ? props.theme.border : 'border-slate-200'}`}
    onClick={() => saveColor(props.color)}>
    {saving ? <>
      <FontAwesomeIcon className={"text-slate-600"} icon={faCircleNotch} spin={true}/>
    </> : <>
      <div className={`w-6 h-6 rounded-full ${props.theme.bg}`}></div>
      <span className={'text-slate-900 font-medium mt-2'}>{props.theme.name}</span>
    </>}
  </button>
}