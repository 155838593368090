import React, {FC} from "react";
import {ModalFooter} from "@traas/lib/src/components/layout/ModalProvider";
import {Workorder} from "../api/dto";
import {ValidationError} from "../api/api";
import {useApi} from "../api/APIContext";

export const ArchiveModal: FC<{order: Workorder}> = (props) => {
  const {archiveWorkOrder} = useApi()
  const [errors, setErrors] = React.useState<string[]>([])
  const archive = async () => {
    await archiveWorkOrder(props.order.workorderId!).catch(e => {
      if (e instanceof ValidationError) {
        setErrors(Object.values(e.errors).flat())
      }
      throw e
    })
  }
  return <form>
    <div>
      <p>Weet je zeker dat je deze werkbon wilt archiveren?</p>
      {errors.length > 0 && <div className={"text-red-600 text-sm"}>
        {errors.map((e, i) => <p key={i}>{e}</p>)}
      </div>}
      <ModalFooter text={'Archiveren'} onSubmit={archive}/>
    </div>
  </form>

}