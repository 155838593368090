import React, {FC} from "react";
import * as fa from "@fortawesome/free-solid-svg-icons"
import {useApi} from "../api/APIContext";
import {ModalFooter} from "@traas/lib/src/components/layout/ModalProvider";
import { Input } from "@traas/lib/src/components/form/Input";
import {ValidationError} from "../api/api";
import {QuantityType, Task, TASK_QUANTITY_TYPE_OTHER} from "../api/dto";
import {Select} from "@traas/lib/src/components/form/Select";


export const EditTaskModal: FC<{task: Task}> = ({task}) => {
  const {updateTaskDescriptionAndQuantity, quantityTypes} = useApi()

  const [name, setName] = React.useState(task.description)
  const defaultQuantityType = quantityTypes.find(qt => {
    const type = task.quantityType ?? TASK_QUANTITY_TYPE_OTHER
    return qt.id === type || qt.name === type
  })?.id ?? ""
  const [quantityType, setQuantityType] = React.useState<string>(defaultQuantityType)
  const [quantityAmount, setQuantityAmount] = React.useState<number|null>(task.quantityAmount ?? 0)
  const [quantityThickness, setQuantityThickness] = React.useState<number|null>(task.quantityThickness ?? 0)
  const [errors, setErrors] = React.useState<string[]>([])
  const confirm = async () => {
    await updateTaskDescriptionAndQuantity(task, name, quantityType, quantityAmount ?? 0, quantityThickness ?? 0)
      .catch(e => {
        if (e instanceof ValidationError) {
          setErrors(Object.values(e.errors).flat())
        }
        throw e
      })
  }

  return <form>
    <div className={"space-y-2"}>
      <div className={'flex-col space-y-2'}>
        <Select label={'Taak type'}
                options={Object.fromEntries(quantityTypes.map((qt: QuantityType) => [qt.id, qt.name]))}
                value={quantityType} onChange={setQuantityType}/>
        {quantityType !== TASK_QUANTITY_TYPE_OTHER &&
          <Input label={'Oppervlakte m²'} value={quantityAmount ?? 0} onChange={setQuantityAmount} type={'number'}/>}
        {quantityType !== TASK_QUANTITY_TYPE_OTHER &&
          <Input label={quantityTypes.find(qt => qt.id === quantityType)?.thicknessUnit === 'geen' ? 'Dikte'
            : `Dikte (${quantityTypes.find(qt => qt.id === quantityType)?.thicknessUnit})`}
                 value={quantityThickness ?? 0} onChange={setQuantityThickness} type={'number'}/>}
      </div>
      <Input label={'Omschrijving'} value={name} focus={true} onChange={setName} type={'text'}/>
      {errors.length > 0 && <div className={"text-red-600 text-sm"}>
        {errors.map((e, i) => <p key={i}>{e}</p>)}
      </div>}
      <ModalFooter icon={fa.faSave} text={"Opslaan"} onSubmit={confirm}/>
    </div>
  </form>
}
