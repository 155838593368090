import React, {FC, useMemo} from "react";
import * as fa from "@fortawesome/free-solid-svg-icons"
import {Employee, TeamMembership} from "../api/dto";
import {useApi} from "../api/APIContext";
import {ModalFooter} from "@traas/lib/src/components/layout/ModalProvider";
import { Input } from "@traas/lib/src/components/form/Input";
import {ValidationError} from "../api/api";
import {Autocomplete} from "@traas/lib/src/components/form/Autocomplete";


export const SwitchTeamModal: FC<{employee: Employee, teamMembership: TeamMembership}> = (props) => {
  const {teams, joinTeam, endTeamMembership} = useApi()
  const [date, setDate] = React.useState(new Date())
  const [removeFromTeam, setRemoveFromTeam] = React.useState(false)
  const [errors, setErrors] = React.useState<string[]>([])

  const availableTeams = useMemo(() => {
    return teams
      .filter(team => !team.isTemporary && team.id !== 'absence' && team.id !== props.teamMembership.teamId)
      .reduce<[string, string][]>((list, team) => {
        if (team.endedAt !== null && team.endedAt.getTime() < date.getTime()) {
          return list;
        }
        list.push([team.id, team.name]);
        return list;
      }, []);
  }, [teams, date, props.teamMembership]);

  const [team, setTeam] = React.useState<string>(Object.keys(availableTeams)[0] ?? '')

  const confirm = async () => {
    if (removeFromTeam) {
      await endTeamMembership(props.teamMembership.teamId, props.teamMembership.id, date)
        .catch(e => {
          if (e instanceof ValidationError) {
            setErrors(Object.values(e.errors).flat())
          }
          throw e
        })
    } else {
      const _baseDate = new Date()
      _baseDate.setHours(0)
      _baseDate.setMinutes(0)
      _baseDate.setSeconds(0)
      if (_baseDate.getTime() > date.getTime()) {
        setErrors(['Datum moet in de toekomst liggen'])
        throw "Datum moet in de toekomst liggen"
      }
      const teamObject = teams.find(t => t.id === team)
      if (team === '' || ! teamObject) {
        setErrors(['Team is verplicht'])
        throw "Team verplicht"
      }
      await joinTeam(teamObject, props.employee, date)
        .catch(e => {
          if (e instanceof ValidationError) {
            setErrors(Object.values(e.errors).flat())
          }
          throw e
        })
    }
  }

  return (
    <form>
      <div className="space-y-4 max-w-md">
        <Input type="date" label="Wijziging gaat in vanaf:" value={date} onChange={setDate}/>
        <label
          className={`flex items-center px-4 mt-4 rounded h-12 border cursor-pointer ${removeFromTeam ? "border-blue-800 bg-blue-50" : "border-slate-200"}`}>
          <input type="checkbox" checked={removeFromTeam} onChange={() => setRemoveFromTeam(x => !x)}/>
          <span className="ml-2 font-medium text-sm">Uit team plaatsen</span>
        </label>
        {!removeFromTeam && (
          <>
            {availableTeams.length === 0 ? (
              <div className="h-12 bg-slate-100 rounded text-sm text-slate-600 flex items-center justify-center">
                Er zijn op die datum geen teams beschikbaar.
              </div>
            ) : (
              <Autocomplete label="Team" options={availableTeams} value={team} onChange={setTeam}/>
            )}
          </>
        )}
        {errors.length > 0 && (
          <div className="text-red-600 text-sm">
            {errors.map((e, i) => (
              <p key={i}>{e}</p>
            ))}
          </div>
        )}
        <ModalFooter icon={fa.faPlus} text="Opslaan" onSubmit={confirm}/>
      </div>
    </form>
  );
}
