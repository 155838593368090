import {FC} from "react";
import {AdvancedMarker, Map} from '@vis.gl/react-google-maps';
import {EmployeeAvatar} from "../personel/components/EmployeeAvatar";
import {Employee} from "../api/dto";

interface MapPosition {lat: number, lng: number}
interface MapMarker {
  employee: Employee
  pos: MapPosition,
}
interface MapWithMarkersProps {
  center: MapPosition,
  zoom: number,
  markers: MapMarker[],
}
export const MapWithMarkers: FC<MapWithMarkersProps> = props => {
  return <Map defaultCenter={props.center} defaultZoom={props.zoom} mapId={'traas-live-map'}>
    {props.markers.map((marker, index) => {
      return <AdvancedMarker key={index} position={marker.pos} title={marker.employee.firstName}>
        <div className={'shadow-lg rounded-full'}>
          <div className={'-m-3 '}>
            <EmployeeAvatar employee={marker.employee} />
          </div>
        </div>
      </AdvancedMarker>
    })}
  </Map>
}