import { ConfigInterface, resolveConfig } from "@traas/lib/src/config"

const config: ConfigInterface = {
  apiBaseUrl: resolveConfig('VITE_API_BASE_URL', 'https://api.traasvloeren.nl/api'),
  realtimeApiUrl: resolveConfig( 'VITE_REALTIME_API_URL','wss://api.traasvloeren.nl/realtime'),
  sentryDsn: resolveConfig('VITE_SENTRY_DSN', ''),
  enableDebugMode: import.meta.env.VITE_ENABLE_DEBUG_MODE ? import.meta.env.VITE_ENABLE_DEBUG_MODE !== 'false' : import.meta.env.MODE === 'development',
  planningUrl: resolveConfig('VITE_PLANNING_URL', 'https://planning.traasvloeren.nl'),
  crmUrl: resolveConfig('VITE_CRM_URL', 'https://crm.traasvloeren.nl'),
  googleApiKey: resolveConfig('VITE_GOOGLE_API_KEY', ''),
  devMode: import.meta.env.DEV,
  prodMode: import.meta.env.PROD,
}
export default config
