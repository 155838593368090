import React from "react";
import {Employee} from "../../api/dto";
import {HoverHint} from "@traas/lib/src/components/content/HoverHint";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faShuffle, IconDefinition} from "@fortawesome/free-solid-svg-icons";

const variants: {[color: string]: { avatar: string, dot: string }} = {
  'blue': {
    avatar: 'bg-blue-100 text-blue-800',
    dot: 'bg-blue-500',
  },
  'green': {
    avatar: 'bg-green-100 text-green-800',
    dot: 'bg-green-500',
  },
  'red': {
    avatar: 'bg-red-100 text-red-800',
    dot: 'bg-red-500',
  },
  'yellow': {
    avatar: 'bg-yellow-100 text-yellow-800',
    dot: 'bg-yellow-500',
  },
  'purple': {
    avatar: 'bg-indigo-100 text-indigo-800',
    dot: 'bg-indigo-500',
  }
}

export const getAvailableColors = (): {[color: string]: string} => {
  return Object.fromEntries(Object.entries(variants).map(([color, {dot}]) => [color, dot]))
}

export const EmployeeAvatar: React.FC<{employee: Employee}> = ({employee}) => {
    const colorVariant = getStyleForEmployee(employee)
    return <div className={`h-10 w-10 border-2 border-white m-3 rounded-full ${colorVariant.avatar} flex items-center justify-center font-light relative`}>
      <HoverHint hint={`${employee.firstName} ${employee.lastName}`}>{employee.firstName.charAt(0).toUpperCase()}{employee.lastName.charAt(0).toUpperCase()}{employee.lastName.charAt(1)}</HoverHint>
    </div>
}
export const TinyEmployeeAvatar: React.FC<{employee: Employee, hint?: {icon: IconDefinition, text: string}, onClick?: () => void}> = ({employee, hint, onClick}) => {
  const colorVariant = getStyleForEmployee(employee)
  const style = `h-6 w-14 rounded-full ${colorVariant.avatar} flex items-center justify-center text-[12px] border border-white relative tracking-tight`
  return <div className={"relative"}>
    {hint && <>
      <div className={"absolute z-10 -top-1 -right-1 h-3 w-3 rounded-full bg-blue-900 text-white flex items-center justify-center group"}>
        <FontAwesomeIcon icon={hint.icon} className={"text-[8px]"} />
        {/* Hint */}
        <span className={"hidden group-hover:flex absolute bottom-[100%] left-[50%] z-10 -translate-x-1/2 transform w-64  justify-center items-center"}>
            <span className={"bg-black text-white text-xs px-2 mt-1 py-1 rounded relative shadow"}>
              {hint.text}
              {/*Arrow*/}
              <div className={"absolute bottom-0 left-[50%] -translate-x-1/2 translate-y-1/2 rotate-45 h-2 w-2 bg-black"}></div>
            </span>
          </span>
      </div>
    </>}
    {onClick ? <>
      <button className={style + " group"} onClick={onClick}>
        <div className={"hidden group-hover:flex items-center justify-center"}>
          <FontAwesomeIcon icon={faShuffle} className={"text-xs"}/>
        </div>
        <div
          className={"block group-hover:hidden"}>{employee.firstName?.slice(0, 4)}.{employee.lastName?.charAt(0).toUpperCase()}.
          {/*className={"block group-hover:hidden"}>{employee.firstName.slice(0, 4)}.{employee.lastName.charAt(0).toUpperCase()}.*/}
        </div>
        {/* Hint */}
        <span
          className={"hidden group-hover:flex absolute top-[100%] left-[50%] z-10 -translate-x-1/2 transform w-64  justify-center items-center"}>
        <span className={"bg-black text-white text-xs px-2 mt-1 py-1 rounded relative shadow"}>
          {employee.firstName} {employee.lastName}{!employee.enabled ? " (Non-Actief)" : ''}
          {/*Arrow*/}
          <div
            className={"absolute top-0 left-[50%] -translate-x-1/2 -translate-y-1/2 rotate-45 h-2 w-2 bg-black"}></div>
        </span>
      </span>
      </button>
    </> : <>
      <div className={style}>
        <HoverHint
          hint={`${employee.firstName} ${employee.lastName}${!employee.enabled ? " (Non-Actief)" : ''}`}>{employee.firstName.slice(0, 4)}.{employee.lastName.charAt(0).toUpperCase()}.</HoverHint>
      </div>
    </>}
  </div>
}

const getStyleForEmployee = (employee: Employee): { avatar: string, dot: string } => {
  if (!employee.enabled) {
    return {
      avatar: 'bg-gray-50 text-gray-400',
      dot: 'bg-gray-500',
    }
  }
  return variants[getVariantForEmployee(employee)]
}

export const getVariantForEmployee = (employee: Employee): string => {
  const variant = employee.metadata.color ?? 'blue'
  return variant in variants ? variant : 'blue'
}
const hasNoStyle = (employee: Employee): boolean => {
  if (employee.metadata.color === undefined || employee.metadata.color === null) {
    return true
  }
  return !Object.keys(variants).includes(employee.metadata.color);
}
