import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import {AuthMiddleware, AuthProvider} from "./auth/AuthProvider";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Planning} from "./pages/Planning";
import {Instellingen} from "./pages/Instellingen";
import {Personeel} from "./pages/Personeel";
import {PlannerContextProvider} from "./planning/PlannerContext";
import {APIContextProvider, useApi} from "./api/APIContext";
import {RealtimeContextProvider} from "./realtime/RealtimeContext";
import {Holidays} from "./pages/Vrije dagen";
import {Teams} from "./pages/Teams";
import {Leveranciers} from "./pages/Leveranciers";
import initSentry from "./sentry";
import {Colors} from "./pages/Kleuren";
import {Orders} from "./pages/Orders";
import {Werkbonnen} from "./pages/Werkbonnen";
import {Reports} from "./pages/Reports";
import {ModalProvider} from "@traas/lib/src/components/layout/ModalProvider";
import {GlobalLoadingScreen} from "@traas/planning/src/components/layout/GlobalLoadingScreen";
import {navigation} from "./navigation";
import {LiveLocation} from "./pages/LiveLocation";
import {APIProvider as GoogleMapsAPIProvider} from "@vis.gl/react-google-maps"
import config from "./config";
import {LiveLocationContextProvider} from "./realtime/LiveLocationContext";

initSentry()

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <AuthProvider>
    <React.StrictMode>
      <BrowserRouter>
        <AuthMiddleware navigation={navigation}>
          <APIContextProvider>
            <LiveLocationContextProvider>
              <RealtimeContextProvider>
                <ModalProvider>
                  <GlobalLoadingScreen>
                    <PlannerContextProvider>
                      <GoogleMapsAPIProvider apiKey={config.googleApiKey}>
                        <Routes>
                          <Route path={"/"} element={<Planning />} />
                          <Route path={"/bestellingen"} element={<Orders />} />
                          <Route path={"/workorders"} element={<Werkbonnen />} />
                          <Route path={"/tracking"} element={<LiveLocation />} />
                          <Route path={"/personeel"} element={<Personeel />} />
                          <Route path={"/teams"} element={<Teams />} />
                          <Route path={"/holidays"} element={<Holidays />} />
                          <Route path={"/reports"} element={<Reports />} />
                          <Route path={"/instellingen"} element={<Instellingen />} />
                          <Route path={"/leveranciers"} element={<Leveranciers />} />
                          <Route path={"/kleuren"} element={<Colors />} />
                        </Routes>
                      </GoogleMapsAPIProvider>
                    </PlannerContextProvider>
                  </GlobalLoadingScreen>
                 </ModalProvider>
              </RealtimeContextProvider>
            </LiveLocationContextProvider>
          </APIContextProvider>
        </AuthMiddleware>
      </BrowserRouter>
    </React.StrictMode>
  </AuthProvider>,
)
