import React, {FC, FormEvent, useCallback, useEffect, useMemo, useRef, useState} from "react";
import { useModalControls} from "@traas/lib/src/components/layout/ModalProvider";
import { Input } from "@traas/lib/src/components/form/Input";
import {Task, TASK_QUANTITY_TYPE_OTHER} from "../api/dto";
import {useApi} from "../api/APIContext";
import {Autocomplete} from "@traas/lib/src/components/form/Autocomplete";
import {ValidationError} from "../api/api";
import {Button, IconButton} from "@traas/lib/src/components/form/Button";
import {faAdd, faMinusCircle, faNoteSticky, faPencil, faSave} from "@fortawesome/free-solid-svg-icons";
import {PillSelect} from "@traas/lib/src/components/form/PillSelect";
import {useTaskTypes} from "../planning/hooks/useTaskTypes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const AddTaskModal: FC<{onSubmit: (tasks: Task[]) => Promise<void>, projectId?: string}> = (props) => {
  const {addTask, addTasks, projects, quantityTypes} = useApi()
  const initialType = quantityTypes.find((a) => a.name === 'Anders');
  const [project, setProject] = useState<string|null>(props.projectId ?? null)
  const [type, setType] = useState<string|null>(initialType?.id.toString() ?? null)
  const [names, setNames] = useState<string[]>([""])
  const [amount, setAmount] = useState(0)
  const [thickness, setThickness] = useState(0)
  const [errors, setErrors] = useState<string[]>([])
  const [note, setNote] = useState<string>('')
  const {close} = useModalControls()
  const {getTaskTemplate} = useTaskTypes()

  const projectOptions: [string, string][] = useMemo(() => {
    return [
      ...(project === null ? [["", "Selecteer project"]] : []),
      ["0", "Eigen taak (Geen project)"],
      ...projects.sort((a, b) => b.projectNumber > a.projectNumber ? 1 : -1).map(p => [p.id, `${p.projectNumber} - ${p.description}`])
    ] as [string, string][];
  }, [projects, project]);

  const typeOptions = useMemo(() => {
    return Object.fromEntries(quantityTypes.map(t => [t.id, t.name]))
  }, [quantityTypes])
  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => {
    const keys = Object.keys(typeOptions)
    if (type === undefined && keys.length > 0) {
      setType(keys[0])
    }
  }, [typeOptions, type])

  const confirm = async () => {
    if (project === null)  {
      setErrors(["Project is verplicht"])
      throw "validation error"
    }
    if (typeof type !== "string" || type.length === 0) {
      setErrors(["Je moet een type selecteren"])
      throw "validation error"
    }
    if (names.some(name => name.length !== 0 && name.length < 3)) {
      setErrors(["Een omschrijving moet minimaal 3 karakters bevatten"])
      throw "validation error"
    }
    setIsSaving(true)
    const addFunc = names.length > 1 ? () => addTasks(project, names, type, amount, thickness, note) : () =>
      addTask(project, names[0], type, amount, thickness, note).then(t => [t])
    await addFunc()
      .catch(e => {
        if (e instanceof ValidationError) {
          setErrors(Object.values(e.errors).flat())
        }
        setIsSaving(false)
        throw e
      })
      .then(async (tasks) => {
        await props.onSubmit(tasks)
      })
    setIsSaving(false)
  }
  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()
    await confirm()
    close()
  }
  const nameRef=  useRef<HTMLInputElement>(null)
  const amountRef=  useRef<HTMLInputElement>(null)
  const thicknessRef=  useRef<HTMLInputElement>(null)
  const typeChanged = useCallback((newType: string) => {
    setType(newType)
    if (newType === TASK_QUANTITY_TYPE_OTHER) {
      nameRef.current?.focus()
    } else {
      const template = getTaskTemplate(newType)
      if (template !== null && template.length > 0 && template[0] !== "") {
        setNames(template)
      }
      setTimeout(() => thicknessRef.current?.focus(), 100)
      setTimeout(() => amountRef.current?.focus(), 100)
    }
  }, [nameRef.current, amountRef.current, getTaskTemplate, type])
  return <form className={"space-y-2"} onSubmit={onSubmit}>
    <Autocomplete label={"Project"} options={projectOptions} value={project ?? ""} onChange={setProject}/>
    <label className={"flex flex-col text-sm font-medium text-blue-900 w-full max-w-md"}>
      <span className={"-mb-2"}>Type</span>
      <PillSelect options={typeOptions} value={type ?? ''} onChange={typeChanged}/>
    </label>
    <div className={type === TASK_QUANTITY_TYPE_OTHER ? 'hidden' : ''}>
      <Input label={'Oppervlakte (m2)'} inputRef={amountRef} value={amount} onChange={setAmount} type={'number'}/>
    </div>
    <div className={type === TASK_QUANTITY_TYPE_OTHER ? 'hidden' : ''}>
      <Input label={quantityTypes.find(qt => qt.id === type)?.thicknessUnit === 'geen' ? 'Dikte'
          : `Dikte (${quantityTypes.find(qt => qt.id === type)?.thicknessUnit})`}
             inputRef={thicknessRef} value={thickness} onChange={setThickness} type={'number'}/>
    </div>
    {names.map((name, i, names) => {
      return <div key={i} className={"flex items-end space-x-2"}>
        <Input label={type !== TASK_QUANTITY_TYPE_OTHER ? 'Omschrijving (optioneel)' : 'Omschrijving'}
               inputRef={i === 0 ? nameRef : undefined} value={name} onChange={(name) => setNames(old => {
          const newNames = [...old]
          newNames[i] = name
          return newNames
        })} type={'text'}/>
        {names.length > 1 && <IconButton type={'secondary'} size={'md'} icon={faMinusCircle}
                                         onClick={() => setNames(old => old.filter((_, j) => j !== i))}/>}
      </div>
    })}
    <Button type={'secondary'} size={'sm'} icon={faAdd} text={'Extra taak toevoegen'}
            onClick={() => setNames(old => [...old, ""])}/>

    {errors.length > 0 && <div className={"text-red-600 text-sm"}>
      {errors.map((e, i) => <p key={i}>{e}</p>)}
    </div>}

    <div className={"grid grid-cols-1"}>
      <Input type={"textarea"} label={"Werkbon notities (optioneel)"} value={note} onChange={setNote}/>
    </div>

    <footer className={"-mx-4 px-4 mt-3 py-3 border-t border-slate-100 flex items-center space-x-4"}>
      <Button type={'primary'} disabled={isSaving} size={'md'} text={isSaving ? 'Opslaan...' : 'Opslaan'} icon={faSave}
              submit onClick={() => {
      }}/>
      <Button type={'secondary'} size={'md'} text={'Terug'} onClick={() => close()}/>
    </footer>
  </form>
}