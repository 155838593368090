import React, {FC, useEffect, useState} from "react";
import {usePersistentState} from "@traas/lib/src/util/usePersistentState";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy, faToggleOff, faToggleOn} from "@fortawesome/free-solid-svg-icons";
import {useKeycloak} from "@react-keycloak/web";
import {Card} from "@traas/lib/src/components/Card";
import {Paragraph} from "@traas/lib/src/components/Paragraph";
import {MapWithMarkers} from "../components/MapWithMarkers";
import {useApiCall} from "../api/api";
import {Employee, EmployeeLocation, QuantityType} from "../api/dto";
import {useApi, useFetchedResource} from "../api/APIContext";
import {EmployeeAvatar} from "../personel/components/EmployeeAvatar";
import moment from "moment";
import {useLiveLocations} from "../realtime/LiveLocationContext";

export const LiveLocation: FC = () => {
  const {employees} = useApi()
  const {currentLocations} = useLiveLocations()

  return (
    <main className={"flex-1 overflow-y-scroll max-h-[calc(100vh-4rem)]"}>
      <div className={"mx-6 my-8"}>
        <h1 className={"h-12 flex items-center text-2xl mb-6 font-bold"}>Live Locatie</h1>
        <div className={'flex items-stretch'}>
          <div className={'flex-1 my-8 mr-4 rounded shadow overflow-hidden min-h-[min(70vh,800px)]'}>
            {/* Location and zoom are Traas Office with zoom that just fits Walcheren and Zuid-Beveland. */}
            <MapWithMarkers center={{lat: 51.4808111, lng: 3.8153836}} zoom={11} markers={[
              ...Object.values(currentLocations).map((location, index) => {
                const emp = employees.find(e => e.id === location.employeeId)
                if (!emp) {
                  return null
                }
                return {pos: {lat: location.lat, lng: location.lon}, employee: emp}
              }).filter(m => m !== null) as { pos: { lat: number, lng: number }, employee: Employee }[]
            ]} />
          </div>
          <Card title={'Medewerkers'}>
            <Paragraph>
              Live locatie van medewerkers van vandaag.
              <div>
                {Object.keys(currentLocations).map((employeeId, index) => {
                  const emp = employees.find(e => e.id === employeeId)
                  if (!emp) {
                    return <div key={index}></div>
                  }
                  return <div key={index} className={'flex items-center h-16'}>
                    <div className={'-m-3'}>
                      <EmployeeAvatar employee={emp} />
                    </div>
                    <div className={'ml-3'}>
                      <strong>{emp.firstName} {emp.lastName}</strong>
                      <div className={'text-slate-500 text-sm'}>Laatste update: {moment(currentLocations[employeeId].createdAt).format('HH:mm')}</div>
                    </div>
                  </div>
                })}
              </div>
            </Paragraph>
          </Card>
        </div>
      </div>
    </main>
  );
}

