import React, {Dispatch, FC, SetStateAction, useState} from "react";
import {useApi} from "../api/APIContext";
import {ModalFooter, useModalControls} from "@traas/lib/src/components/layout/ModalProvider";
import {Task} from "../api/dto";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faTimes,
  faWarning
} from "@fortawesome/free-solid-svg-icons";
import {dateToString} from "../planning/util/datetime";
import {Button} from "@traas/lib/src/components/form/Button";
import {ValidationError} from "../api/api";
import * as fa from "@fortawesome/free-solid-svg-icons";
import {SingleSelectCalendar} from "@traas/lib/src/components/form/MultiSelectCalendar";


export const MoveTaskModal: FC<{task: Task, setDay: Dispatch<SetStateAction<Date>>}> = (props) => {
  const {planTask, taskToBuffer} = useApi()
  const { close } = useModalControls()
  const [selected, setSelected] = useState<Date|null>(null)
  const [errors, setErrors] = React.useState<string[]>([])
  const [isSaving, setIsSaving] = useState(false)
  const confirm = async () => {
    if (!selected) {
      setErrors(['Selecteer een datum om de taak naar te verplaatsen'])
      throw 'validation error'
    }
    setIsSaving(true)
    await planTask(props.task, props.task.teamId, selected, props.task.durationMinutes ?? 60)
      .catch(async e => {
        if (e instanceof ValidationError) {
          await taskToBuffer(props.task, selected)
          setErrors(['Geen ruimte in planning, taak is verplaatst naar het Overschot vlak.'])
        }
        throw e
      })
  }

  if (Object.keys(errors).length > 0) {
    return <form>
      <div className={"flex flex-col items-center justify-center space-y-2 py-4"}>
        <FontAwesomeIcon icon={faWarning} className={'text-xl text-red-900'}/>
        <h2 className={"font-medium text-lg text-red-900 pb-4"}>Kon taak niet verplaatsen:</h2>
        <div className={"border border-slate-200 rounded w-full"}>
          <table className={"w-full"}>
            <thead>
            <tr>
              <th className={"p-2 border-b text-xs uppercase text-left font-medium text-red-600"}>Foutmelding</th>
              <th className={"p-2 border-b"}></th>
            </tr>
            </thead>
            <tbody>
            {errors.map((error, i) => {
              return <tr key={i}>
                <td className={"py-1 px-2 text-sm font-medium text-red-600"}>{error}</td>
                <td className={"py-1 px-2 text-sm font-medium"}>
                  {selected && <Button type={'secondary'} size={'sm'} text={'Naar week'} icon={faArrowRight}
                                       onClick={() => props.setDay(selected)}/>}
                </td>
              </tr>
            })}
            </tbody>
          </table>
        </div>
        <Button type={'secondary'} size={'md'} text={'Oke'} onClick={() => close()}/>
      </div>
    </form>
  }

  return <form>
    <div className={"space-y-2"}>
      <div className={"flex space-x-4"}>
        <div>
          <SingleSelectCalendar taskDate={props.task.startAt!} setSelected={setSelected} selected={selected}/>
        </div>
        <div className={"flex-1"}>
          {selected ? <div className={"max-h-[30vh] overflow-y-auto"}>
            <h2 className={"font-medium text-slate-900 mb-4"}>Verplaatsen naar:</h2>
            <button type={"button"}
              className={"flex space-x-2 items-center group justify-between hover:bg-red-100 h-8 mb-1 px-2 w-full border border-slate-100 rounded"}
              onClick={() => setSelected(null)}>
              <span className={"text-slate-900 text-sm"}>{dateToString(selected)} {selected.getFullYear()}</span>
              <FontAwesomeIcon icon={faTimes} className={"text-sm text-slate-500 group-hover:text-red-500"}/>
            </button>
          </div> : <p className={"text-center text-slate-600 text-sm p-4"}>
            Selecteer een datum om de afspraak naar te verplaatsen. <br/>
            De afspraak zal dan ingepland worden op deze dag, op de zelfde tijd als de originele afspraak.
          </p>}

        </div>
      </div>
      <ModalFooter icon={fa.faSave} text={"Opslaan"} onSubmit={confirm}/>
    </div>
  </form>

}
