import React, {Dispatch, FC, SetStateAction, useMemo, useState} from "react";
import moment from "moment/moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";


export const SingleSelectCalendar: FC<{taskDate: Date, selected: Date|null, setSelected: Dispatch<SetStateAction<Date|null>>}> = props => {
  function select(d: Date) {
    props.setSelected(d)
  }
  return <SelectCalendar taskDate={props.taskDate} selected={props.selected ? [props.selected] : []} onSelect={select} />
}
export const MultiSelectCalendar: FC<{taskDate: Date, selected: Date[], setSelected: Dispatch<SetStateAction<Date[]>>}> = props => {
  function select(d: Date) {
    props.setSelected((old) => {
      const wasSelected = old.find(o => o.toDateString() === d.toDateString())
      if (wasSelected) {
        return old.filter(o => o.toDateString() !== d.toDateString())
      }
      return [...old, d]
    })
  }
  return <SelectCalendar taskDate={props.taskDate} selected={props.selected} onSelect={select} />
}
const SelectCalendar: FC<{taskDate: Date, selected: Date[], onSelect: (date: Date) => void}> = props => {
  const [selectedMonth, setSelectedMonth] = useState(new Date(props.taskDate.getFullYear(), props.taskDate.getMonth(), 1))

  function nextMonth() {
    setSelectedMonth(new Date(selectedMonth.getFullYear(), selectedMonth.getMonth() + 1, 1))
  }
  function prevMonth() {
    setSelectedMonth(new Date(selectedMonth.getFullYear(), selectedMonth.getMonth() - 1, 1))
  }

  const options = useMemo(() => {
    const options: Date[] = []
    const firstDay = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth(), 1)
    const lastDay = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth() + 1, 0)
    for (let i = -firstDay.getDay()+1; i <= lastDay.getDate(); i++) {
      options.push(moment(firstDay).add(i, 'day').toDate())
    }
    return options
  }, [selectedMonth])

  return <div className={' w-80 rounded-2xl bg-white border border-slate-200 z-50'}>
    <div className={'border-t border-slate-100 px-4 py-3'}>
      <div className={"w-full flex items-center justify-between"}>
        <button type={'button'} className={"h-8 w-8 rounded-full hover:bg-slate-200"} onClick={() => prevMonth()}><FontAwesomeIcon icon={faChevronLeft} /></button>
        <span>{ selectedMonth.toLocaleDateString('nl-NL', selectedMonth.getFullYear() === new Date().getFullYear() ? {month: "long"} : {month: "long", year: "numeric"}) }</span>
        <button type={'button'} className={"h-8 w-8 rounded-full hover:bg-slate-200"} onClick={() => nextMonth()}><FontAwesomeIcon icon={faChevronRight} /></button>
      </div>
      <div className={"grid grid-cols-8 gap-x-1 mt-2"}>
        <div className={"text-xs text-slate-500 border-r border-slate-100 text-center"}>#</div>
        <div className={"text-xs text-slate-500 text-center"}>Ma</div>
        <div className={"text-xs text-slate-500 text-center"}>Di</div>
        <div className={"text-xs text-slate-500 text-center"}>Wo</div>
        <div className={"text-xs text-slate-500 text-center"}>Do</div>
        <div className={"text-xs text-slate-500 text-center"}>Vr</div>
        <div className={"text-xs text-slate-500 text-center"}>Za</div>
        <div className={"text-xs text-slate-500 text-center"}>Zo</div>
        {options.map((option, index) => {
          if (option.getMonth() !== selectedMonth.getMonth()) {
            return <div key={index} className={option.getDay() === 1 ? "col-span-2" : ''}></div>
          }
          const isToday = option.toDateString() === props.taskDate.toDateString()
          const isSelected = props.selected.find(o => o.toDateString() === option.toDateString())
          const disabled = isToday || option.getDay() === 0 // zondag
          const button = <button type={'button'} key={index} disabled={disabled} onClick={() => props.onSelect(option)} className={`text-center ${disabled ? 'text-slate-400' : 'hover:bg-slate-100'} ${!disabled && isSelected && 'bg-blue-200 hover:bg-blue-300 text-blue-900'} border  ${isToday ? 'border-blue-900' : 'border-transparent'} rounded-full h-8 w-8`}>{ option.getDate() }</button>

          if (option.getDay() === 1) {
            return <div key={index} className={'col-span-2 grid grid-cols-2 gap-x-1'}>
              <div className={"text-center flex items-center justify-center text-xs font-light text-slate-500 border-r border-slate-100"}>{moment(option).week()}</div>
              <div className={""}>{button}</div>
            </div>
          } else {
            return button
          }
        })}
      </div>
    </div>
  </div>
}