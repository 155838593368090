import React, {FC, useCallback, useEffect, useMemo, useState} from "react";
import {
  Employee,
  EmployeeWorkDays,
  Project,
  Team,
  Task,
  Holiday,
  Supplier,
  PlanningAbsence,
  QuantityType,
  Color, Note, Order, Workorder, DailyRemark
} from "./dto";
import {useApiCall} from "./api";

interface APIContextType {
  projects: Project[]
  tasks: Task[]
  employees: Employee[]
  teams: Team[]
  holidays: Holiday[]
  suppliers: Supplier[]
  quantityTypes: QuantityType[]
  colors: Color[]
  notes: Note[]
  orders: Order[]
  workorders: Workorder[]
  dailyRemarks: DailyRemark[]
  archiveWorkOrder: (orderId: string) => Promise<void>
  unarchiveWorkOrder: (orderId: string) => Promise<void>
  deleteWorkorders: (workOrders: Workorder[]) => Promise<void>
  addTeam: (team: Pick<Team, "name">, isTemp?: boolean, date?: Date) => Promise<void>
  editTeam: (id: string, name: string, category: string, capacity?: number) => Promise<void>
  deleteTeam: (team: Team, date: Date) => Promise<void>
  joinTeam: (team: Team, employee: Employee, date: Date, isTemporary?: boolean, reason?: string, endDate?: Date, paidVacation?: boolean) => Promise<void>
  cancelPlannedChange: (teamId: string, membershipId: string) => Promise<void>
  endTeamMembership: (teamId: string, membershipId: string, endDate: Date) => Promise<void>
  getWorkDays(employee: Employee): Promise<EmployeeWorkDays>
  setWorkDays(employee: Employee, days: EmployeeWorkDays): Promise<void>
  addEmployee(employee: Pick<Employee, "firstName"|"lastName"|"email">): Promise<void>
  updateEmployee(employee: Pick<Employee, "id"|"firstName"|"lastName"|"enabled"|"email">, archiveDate?: Date): Promise<void>
  updateEmployeePassword(employee: Pick<Employee, "id">, password: string): Promise<void>
  updateEmployeeMetadata(employeeId: string, color?: string|null, bsn?: string|null, contractStartDate?: Date|null, contractEndDate?: Date|null): Promise<void>
  addTask(projectId: string, description: string, type?: string, amount?: number, thickness?: number, note?: string): Promise<Task>
  addTasks(projectId: string, descriptions: string[], type?: string, amount?: number, thickness?: number, note?: string): Promise<Task[]>
  planTask(task: Task, teamId: string|null, date: Date, durationMinutes: number): Promise<void>
  unPlanTask(task: Task): Promise<void>
  taskToBuffer(task: Task, date: Date): Promise<void>
  batchPlanTask(taskId: string, dates: Date[]): Promise<{[date: string]: string}>
  updateTaskColor(task: Task, color: string): Promise<void>
  updateTaskDescriptionAndQuantity(task: Task, description: string, quantityType: string, quantityAmount: number, quantityThickness: number): Promise<void>
  updateTaskInvoicedStatus(task: Task, invoiced: boolean): Promise<void>
  updateTaskVoidedStatus(task: Task, voided:boolean): Promise<void>
  deleteTask(task: Task): Promise<void>
  addHoliday(dates: Date[], description: string, type: string): Promise<void>
  removeHoliday(holiday: Holiday): Promise<void>
  addQuantityType(name: string, enabled: boolean, defaultColorId: string|null, descriptionTemplate: string[]|null, hideArea: boolean): Promise<void>
  editQuantityType(id: string, name: string, enabled: boolean, defaultColorId: string|null, descriptionTemplate: string[]|null, hideArea: boolean): Promise<void>
  editNote(date: Date, text: string): Promise<void>
  editDailyRemark(date: Date, teamId: string, remark: string): Promise<void>
  loading: boolean
  initialLoading: boolean
  contextDate: Date
  setContextDate(date: Date): void
  resetLocalChanges(): Promise<void>
  onTaskCreated(task: Task): void
  onTaskUpdated(task: Task): void
  onTaskDeleted(taskId: string): void
  onTasksUpdated(): void
  onProjectsUpdated(): void
  onEmployeesUpdated(): void
  onTeamsUpdated(): void
  onHolidaysUpdated(): void
  onSuppliersUpdated(): void
  onColorsUpdated(): void
  onQuantityTypesUpdated(): void
  onNotesUpdated(): void
  onDailyRemarksUpdated(): void
  onOrdersUpdated(): void
  onWorkordersUpdated(): void
  onWorkordersJobRan(total: number): void
  onWorkordersJobWarning(total: number): void
  addSupplier(name: string): Promise<void>
  editSupplier(id: string, name: string): Promise<void>
  editRoles(id: string, isOffice: boolean, isManager: boolean): Promise<void>
  addTaskSupplier(taskId: string, supplierId: string, description: string|null, status: "green"|"orange"|"red"): Promise<void>
  updateTaskSupplierStatus(taskId: string, supplierId: string, status: "green"|"orange"|"red"): Promise<void>
  updateTaskSupplierDescription(taskId: string, supplierId: string, status: string, description: string|null): Promise<void>
  updateTaskMetadata(taskId: string, fields: Partial<{workorderRemark: string|null, projectRemark: string|null, todos: {[text: string]: boolean}}>): Promise<void>
  deleteTaskSupplier(taskId: string, supplierId: string): Promise<void>
  getPlanningAbsence(date: Date): Promise<PlanningAbsence[]>
  updateTeamsOrder(teams: string[]): Promise<void>
  updateColor(id: string, name: string, enabled: boolean, color: string): Promise<void>

  createOrder(projectId: number, date: Date, text: string, amount?: number, quantity?: number, quantityUnit?: string, remark?: string): Promise<Order>
  updateOrder(id: string, projectId?: number, date?: Date, text?: string, amount?: number, quantity?: number, quantityUnit?: string, remark?: string, isDone?: boolean): Promise<Order>
  deleteOrder(id: string): Promise<void>
  sendWorkorders(tasks: Task[], note?: string): Promise<void>
}
export const APIContext = React.createContext<APIContextType>({} as APIContextType)

export const APIContextProvider: FC<{children: React.ReactNode}> = ({children}) => {
  const [contextDate, setContextDate] = useState(new Date())
  const api = useApiCall()
  const projects = useFetchedResource<Project[], Date>((d) => api.getProjects(d))
  const tasks = useFetchedResource<Task[], Date>((d) => api.getTasks(d))
  const holidays = useFetchedResource<Holiday[], Date>((d) => api.getGlobalHolidays(d))
  const employees = useFetchedResource<Employee[]>(() => api.getEmployees())
  const teams = useFetchedResource<Team[], Date>((d) => api.getTeams(d))
  const suppliers = useFetchedResource<Supplier[]>(() => api.getSuppliers())
  const quantityTypes = useFetchedResource<QuantityType[]>(() => api.getQuantityTypes())
  const colors = useFetchedResource<Color[]>(() => api.getColors())
  const notes = useFetchedResource<Note[], Date>((d) => api.getNotes(d))
  const orders = useFetchedResource<Order[]>(() => api.getOrders())
  const workorders = useFetchedResource<Workorder[], Date>((d) => api.getWorkorders(d))
  const dailyRemarks = useFetchedResource<DailyRemark[], Date>((d) => api.getDailyRemarks(d))

  const [localTaskChanges, setLocalTaskChanges] = useState<{[key: string]: Task|null}>({})

  useEffect(() => {
    projects.reload(contextDate)
    employees.reload(undefined)
    teams.reload(contextDate)
    tasks.reload(contextDate)
    holidays.reload(contextDate)
    workorders.reload(contextDate)
    suppliers.reload(undefined)
    quantityTypes.reload(undefined)
    colors.reload(undefined)
    notes.reload(contextDate)
    orders.reload(undefined)
    dailyRemarks.reload(contextDate)
  }, [contextDate]);

  const resetLocalChanges = useCallback(async () => {
    await tasks.reload(contextDate)
    setLocalTaskChanges({})
  }, [tasks.reload, contextDate])

  const tasksWithLocalChanges = useMemo(() => {
    const map = tasks.resource?.reduce((map, task) => {
      map[task.id] = task
      return map
    }, {} as {[id: string]: Task}) ?? {}
    const localTaskWriteChanges = Object.fromEntries(Object.entries(localTaskChanges).filter(([, task]) => task !== null)) as {[taskId: string]: Task}
    const tasksWithWriteChanges = Object.values({...map, ...localTaskWriteChanges})
    const deletedTasks = Object.entries(localTaskChanges).filter(([, task]) => task === null).map(([id, _]) => id)
    return tasksWithWriteChanges.filter(task => !deletedTasks.includes(task.id))
  }, [tasks, localTaskChanges])


  // const tasksWithLocalChanges = useMemo(() => {
  //   if (! tasks.resource) {
  //     return {} as { [id: string]: Task }
  //   }
  //   const map = tasks.resource.reduce<{[id: string]: Task}>((map, task) => {
  //     map[task.id] = task
  //     return map
  //   }, {} as {[id: string]: Task}) ?? {}
  //   const localTaskWriteChanges = Object.fromEntries(Object.entries(localTaskChanges).filter(([, task]) => task !== null)) as {[taskId: string]: Task}
  //   const tasksWithWriteChanges = Object.values({...map, ...localTaskWriteChanges})
  //   const deletedTasks = Object.entries(localTaskChanges).filter(([, task]) => task === null).map(([id, _]) => id)
  //   return tasksWithWriteChanges.filter(task => !deletedTasks.includes(task.id))
  // }, [tasks, localTaskChanges])

  return <APIContext.Provider value={{
    // Apply local changes
    onTaskCreated(task: Task) {
      setLocalTaskChanges(changes => {
        return {...changes, [task.id]: task}
      })
    },
    onTaskUpdated(task: Task) {
      setLocalTaskChanges(changes => {
        return {...changes, [task.id]: task}
      })
    },
    onTaskDeleted(taskId: string) {
      setLocalTaskChanges(changes => {
        return {...changes, [taskId]: null}
      })
    },
    onTasksUpdated: useCallback(() => {
      tasks.reload(contextDate)
    }, [projects.reload, contextDate]),
    onProjectsUpdated: useCallback(() => {
      projects.reload(contextDate)
    }, [projects.reload, contextDate]),
    onEmployeesUpdated: useCallback(() => {
      employees.reload(undefined)
    }, [employees.reload]),
    onTeamsUpdated: useCallback(() => {
      teams.reload(contextDate)
    }, [teams.reload, contextDate]),
    onHolidaysUpdated: useCallback(() => {
      holidays.reload(contextDate)
    }, [holidays.reload, contextDate]),
    onSuppliersUpdated: useCallback(() => {
      suppliers.reload(undefined)
    }, [suppliers.reload]),
    onColorsUpdated: useCallback(() => {
      colors.reload(undefined)
    }, [colors.reload]),
    onNotesUpdated: useCallback(() => {
      notes.reload(contextDate)
    }, [notes.reload, contextDate]),
    onDailyRemarksUpdated: useCallback(() => {
      dailyRemarks.reload(contextDate)
    }, [dailyRemarks.reload, contextDate]),
    onQuantityTypesUpdated: useCallback(() => {
      quantityTypes.reload(undefined)
    }, [quantityTypes.reload]),
    onOrdersUpdated: useCallback(() => {
      orders.reload(undefined)
    }, [orders.reload]),
    onWorkordersUpdated: useCallback(() => {
      workorders.reload(contextDate)
    }, [workorders.reload, contextDate]),
    onWorkordersJobRan: useCallback((count) => {
      workorders.reload(contextDate)
      alert(`Er zijn ${count} werkbonnen verstuurd.`)
    }, [workorders.reload, contextDate]),
    onWorkordersJobWarning: useCallback((count) => {
      workorders.reload(contextDate)
      alert(`Om 13:00 uur worden ${count} werbonnen verzonden.`)
    }, [workorders.reload, contextDate]),

    // State
    loading: (projects.loading || employees.loading || tasks.loading || teams.loading),
    initialLoading: (projects.loading || employees.loading || tasks.loading || teams.loading) && (!projects.resource || !tasksWithLocalChanges || !employees.resource || !teams.resource || !holidays.resource || !suppliers.resource),
    contextDate,
    setContextDate: (date) => setContextDate(date),
    resetLocalChanges,

    // Data
    projects: projects.resource ?? [],
    // // Test Data:
    // projects: [
    //   new Project('5', '123456', 'Een µooi project', 'planned', new ProjectDeliveryDetails('a', 'b', 'c', 'd', 'e', 'f', 'g'))
    // ],
    tasks: tasksWithLocalChanges,
    employees: employees.resource ?? [],
    teams: teams.resource ?? [],
    holidays: holidays.resource ?? [],
    suppliers: suppliers.resource ?? [],
    quantityTypes: quantityTypes.resource ?? [],
    colors: colors.resource ?? [],
    notes: notes.resource ?? [],
    orders: orders.resource ?? [],
    workorders: workorders.resource ?? [],
    dailyRemarks: dailyRemarks.resource ?? [],

    // Actions
    addTeam: useCallback(async (team: Pick<Team, "name">, isTemp = false, date?: Date) => {
      await api.addTeam(team, isTemp, date)
      await teams.reload(contextDate)
    }, [api.addTeam, teams.reload, contextDate]),
    editTeam: useCallback(async (id: string, name: string, category: string, capacity?: number) => {
      await api.editTeam({id, name, category}, capacity)
      await teams.reload(contextDate)
    }, [api.addTeam, teams.reload, contextDate]),
    deleteTeam: useCallback(async (team: Team, date: Date) => {
      await api.deleteTeam(team, date)
      await teams.reload(contextDate)
    }, [api.deleteTeam, teams.reload, contextDate]),
    joinTeam: useCallback(async (team: Team, employee: Employee, date: Date, isTemporary?: boolean, reason?: string, endDate?: Date, paidVacation?: boolean) => {
      await api.joinTeam(team, employee, date, isTemporary, reason, endDate, paidVacation)
      await teams.reload(contextDate)
    }, [api.joinTeam, teams.reload, contextDate]),
    updateTeamsOrder: useCallback(async (teams: string[]) => {
      await api.updateTeamsOrder(teams)
    }, [api.updateTeamsOrder]),
    cancelPlannedChange: useCallback(async (teamId: string, membershipId: string) => {
      await api.cancelPlannedChange(teamId, membershipId)
      await teams.reload(contextDate)
    }, [api.cancelPlannedChange, teams.reload, contextDate]),
    endTeamMembership: useCallback(async (teamId: string, membershipId: string, endDate: Date) => {
      await api.endTeamMembership(teamId, membershipId, endDate)
      await teams.reload(contextDate)
    }, [api.cancelPlannedChange, teams.reload, contextDate]),
    getWorkDays: useCallback((employee: Employee) => {
      return api.getEmployeeWorkingDays(employee)
    }, [api.getEmployeeWorkingDays]),
    setWorkDays: useCallback(async (employee: Employee, days: EmployeeWorkDays) => {
      await api.updateEmployeeWorkingDays(employee, days)
      await employees.reload(undefined)
    }, [api.updateEmployeeWorkingDays, employees.reload]),
    addEmployee: useCallback(async (employee: Employee) => {
      await api.addEmployee(employee)
      await employees.reload(undefined)
    }, [api.updateEmployee, employees.reload]),
    updateEmployee: useCallback(async (employee: Employee, archiveDate?: Date) => {
      await api.updateEmployee(employee, archiveDate)
      await employees.reload(undefined)
    }, [api.updateEmployee, employees.reload]),
    updateEmployeePassword: useCallback(async (employee: Employee, password: string) => {
      await api.updateEmployeePassword(employee, password)
      await employees.reload(undefined)
    }, [api.updateEmployee, employees.reload]),
    updateEmployeeMetadata: useCallback(async (employeeId: string, color?: string|null, bsn?: string|null, contractStartDate?: Date|null, contractEndDate?: Date|null) => {
      await api.saveEmployeeColor(employeeId, color, bsn, contractStartDate, contractEndDate)
      await employees.reload(undefined)
    }, [api.saveEmployeeColor, employees.reload]),
    addTask: useCallback(async (projectId: string, description: string, type?: string, amount?: number, thickness?: number, note?: string) => {
      const task = await api.addTask(projectId, description, type, amount, thickness, note)
      setLocalTaskChanges((changes) => {
        return ({...changes, [task.id]: task});
      })
      return task
    }, [api.addTask]),
    addTasks: useCallback(async (projectId: string, descriptions: string[], type?: string, amount?: number, thickness?: number, note?: string) => {
      const tasks = await api.addTasks(projectId, descriptions, type, amount, thickness, note)
      setLocalTaskChanges((changes) => {
        return ({...changes, ...Object.fromEntries(tasks.map(task => [task.id, task]))});
      })
      return tasks
    }, [api.addTasks]),
    planTask: useCallback(async (task: Task, teamId: string | null, date: Date, durationMinutes: number) => {
      setLocalTaskChanges((changes) => {
        return ({...changes, [task.id]: {...task, startAt: date, durationMinutes, teamId}});
      })
      await api.planTask(task.id, teamId, date, durationMinutes)
        .catch(e => {
          // Reverse the local change
          setLocalTaskChanges((changes) => {
            return ({...changes, [task.id]: {...task}});
          })
          throw e
        })
    }, [api.planTask]),
    unPlanTask: useCallback(async (task: Task) => {
      setLocalTaskChanges((changes) => {
        return ({...changes, [task.id]: {...task, startAt: null, durationMinutes: null, teamId: null}});
      })
      await api.unPlanTask(task.id)
    }, [api.unPlanTask]),
    taskToBuffer: useCallback(async (task: Task, date: Date) => {
      setLocalTaskChanges((changes) => {
        return ({...changes, [task.id]: {...task, startAt: date, durationMinutes: null, teamId: null}});
      })
      await api.taskToBuffer(task.id, date)
    }, [api.taskToBuffer]),
    batchPlanTask: useCallback(async (taskId: string, dates: Date[]) => {
      return await api.batchPlanTask(taskId, dates)
    }, [api.batchPlanTask]),
    updateTaskDescriptionAndQuantity: useCallback(async (task: Task, description: string, quantityType: string, quantityAmount: number, quantityThickness: number) => {
      setLocalTaskChanges((changes) => {
        return ({
          ...changes,
          [task.id]: {...task, description, quantityType: quantityType, quantityAmount: quantityAmount, quantityThickness: quantityThickness}
        });
      })
      await api.updateTaskDescriptionAndQuantity(task.id, description, quantityType, quantityAmount, quantityThickness)
    }, [api.unPlanTask]),
    updateTaskColor: useCallback(async (task: Task, color: string) => {
      setLocalTaskChanges((changes) => {
        return ({...changes, [task.id]: {...task, color,}});
      })
      await api.updateTaskColor(task.id, color)
    }, [api.updateTaskColor]),
    updateTaskInvoicedStatus: useCallback(async (task: Task, invoiced: boolean) => {
      setLocalTaskChanges((changes) => {
        return ({...changes, [task.id]: {...task, isInvoiced: invoiced,}});
      })
      await api.updateTaskInvoicedState(task.id, invoiced)
    }, [api.updateTaskSupplierDescription]),
    updateTaskVoidedStatus: useCallback(async (task: Task, voided: boolean) => {
      setLocalTaskChanges((changes) => {
        return ({...changes, [task.id]: {...task, isVoided: voided,}});
      })
      await api.updateTaskVoidedState(task.id, voided)
    }, [api.updateTaskSupplierStatus]),
    deleteTask: useCallback(async (task: Task) => {
      await api.deleteTask(task)
    }, [api.deleteTask, tasks.reload]),
    addHoliday: useCallback(async (dates: Date[], description: string, type: string) => {
      await api.addGlobalHoliday(dates, description, type)
    }, [api.addGlobalHoliday, holidays.reload]),
    removeHoliday: useCallback(async (holiday: Holiday) => {
      await api.deleteGlobalHoliday(holiday)
    }, [api.deleteGlobalHoliday, holidays.reload]),
    addQuantityType: useCallback(async (name: string, enabled: boolean, defaultColorId: string|null, descriptionTemplate: string[]|null, hideArea: boolean) => {
      await api.addQuantityType(name, enabled, defaultColorId, descriptionTemplate, hideArea)
    }, [api.addQuantityType, quantityTypes.reload]),
    editQuantityType: useCallback(async (id: string, name: string, enabled: boolean, defaultColorId: string|null, descriptionTemplate: string[]|null, hideArea: boolean) => {
      await api.editQuantityType(id, name, enabled, defaultColorId, descriptionTemplate, hideArea)
    }, [api.editQuantityType, quantityTypes.reload]),
    addSupplier: useCallback(async (name: string) => {
      await api.addSupplier({name})
    }, [api.addSupplier, suppliers.reload]),
    editSupplier: useCallback(async (id: string, name: string) => {
      await api.editSupplier({id, name})
    }, [api.editSupplier, suppliers.reload]),
    editRoles: useCallback(async (id: string, isOffice: boolean, isManager: boolean) => {
      await api.editRoles(id, isOffice, isManager)
    }, [api.editRoles]),
    addTaskSupplier: useCallback(async (taskId: string, supplierId: string, description: string | null, status: "green" | "orange" | "red") => {
      await api.addTaskSupplier(taskId, supplierId, description, status)
    }, [api.addTaskSupplier, tasks.reload]),
    updateTaskSupplierStatus: useCallback(async (taskId: string, supplierId: string, status: "green" | "orange" | "red") => {
      await api.updateTaskSupplierStatus(taskId, supplierId, status)
    }, [api.updateTaskSupplierStatus, tasks.reload]),
    updateTaskSupplierDescription: useCallback(async (taskId: string, supplierId: string, status: string, description: string | null) => {
      await api.updateTaskSupplierDescription(taskId, supplierId, status, description)
    }, [api.updateTaskSupplierDescription]),
    updateTaskMetadata: useCallback(async (taskId: string, fields: Partial<{workorderRemark: string|null, projectRemark: string|null}>) => {
      await api.updateTaskMetadata(taskId, fields)
    }, [api.updateTaskMetadata]),
    deleteTaskSupplier: useCallback(async (taskId: string, supplierId: string): Promise<void> => {
      await api.deleteTaskSupplier(taskId, supplierId)
    }, [api.deleteTaskSupplier]),
    getPlanningAbsence: useCallback(async (date: Date) => {
      return await api.getPlanningAbsence(date)
    }, [api.getPlanningAbsence]),
    updateColor: useCallback(async (id: string, name: string, enabled: boolean, color: string) => {
      await api.updateColor(id, name, enabled, color)
    }, [api.updateColor]),
    editNote: useCallback(async (date: Date, text: string) => {
      await api.editNote(date, text)
    }, [api.editNote]),
    editDailyRemark: useCallback(async (date: Date, teamId: string, remark: string) => {
      await api.editDailyRemark(date, teamId, remark)
    }, [api.editDailyRemark]),
    createOrder: useCallback(async (projectId: number, date: Date, text: string, amount?: number, quantity?: number, quantityUnit?: string, remark?: string) => {
      return api.addOrder(projectId, date, text, amount, quantity, quantityUnit, remark)
    }, [api.addOrder]),
    updateOrder: useCallback(async (id: string, projectId?: number, date?: Date, text?: string, amount?: number, quantity?: number, quantityUnit?: string, remark?: string, isDone?: boolean) => {
      return api.updateOrder(id, projectId, date, text, amount, quantity, quantityUnit, remark, isDone)
    }, [api.updateOrder]),
    deleteOrder: useCallback(async (id: string) => {
      return api.deleteOrder(id)
    }, [api.deleteOrder]),
    sendWorkorders: useCallback(async (tasks: Task[], note?: string) => {
      return api.sendWorkorders(tasks.map(t => t.id), note)
    }, [api.sendWorkorders]),
    archiveWorkOrder: useCallback(async (id: string) => {
      return api.archiveWorkOrder(id)
    }, [api.archiveWorkOrder]),
    unarchiveWorkOrder: useCallback(async (id: string) => {
      return api.unarchiveWorkOrder(id)
    }, [api.unarchiveWorkOrder]),
    deleteWorkorders: useCallback(async (workOrders: Workorder[]) => {
      return api.deleteWorkorders(workOrders.map(w => w.workorderId).filter(id => !!id) as string[])
    }, [api.deleteWorkorders]),
  }}>{children}</APIContext.Provider>
}

export function useApi() {
  return React.useContext(APIContext)
}

export function useFetchedResource<T, P = undefined>(get: (param: P) => Promise<T>) {
  const [resource, setResource] = useState<T | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(false);

  const fetchResource = useCallback(async (param: P, fetchSilently = false) => {
    if (!fetchSilently) {
      setLoading(true);
    }
    let fetchedResource = null
    try {
      fetchedResource = await get(param);
      setResource(fetchedResource);
    } catch (error) {
      // @ts-ignore
      setError(error);
    } finally {
      if (!fetchSilently) {
        setLoading(false);
      }
    }
    return fetchedResource
  }, []);

  return { resource, error, loading, reload: fetchResource };
}
