import React, {FC} from "react";
import {ModalFooter} from "@traas/lib/src/components/layout/ModalProvider";
import {Workorder} from "../api/dto";
import {ValidationError} from "../api/api";
import {useApi} from "../api/APIContext";

export const DeleteWorkordersModal: FC<{orders: Workorder[]}> = (props) => {
  const {deleteWorkorders} = useApi()
  const [errors, setErrors] = React.useState<string[]>([])
  const archive = async () => {
    await deleteWorkorders(props.orders).catch(e => {
      if (e instanceof ValidationError) {
        setErrors(Object.values(e.errors).flat())
      }
      throw e
    })
  }
  return <form>
    <div>
      <p>Weet je zeker dat je {props.orders.length} {props.orders.length == 1 ? 'werkbon' : 'werkbonnen'} wilt
        intrekken?</p>
      <p>Als de werkbonnen toch data/fotos blijken te hebben wordt er niets verwijderd</p>
      {errors.length > 0 && <div className={"text-red-600 text-sm"}>
        {errors.map((e, i) => <p key={i}>{e}</p>)}
      </div>}
      <ModalFooter text={'Intrekken'} onSubmit={archive}/>
    </div>
  </form>

}