import React, {FC, ReactNode, useContext, useEffect} from "react";
import {usePlanner} from "../PlannerContext";
import {RealtimeContext} from "../../realtime/RealtimeContext";
import moment from "moment";
import config from "../../config";

export const PlanningContainer: FC<{children: ReactNode}> = ({children}) => {
  const {scaleConfig, setScale} = usePlanner()
  const [viewportWidth, setViewportWidth] = React.useState(window.innerWidth)
  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  useEffect(() => {
    if (viewportWidth < 2400) {
      setScale(1)
    }
    if (viewportWidth > 2400) {
      setScale(1.5)
    }
  }, [viewportWidth])

  return <div className={"flex flex-col mr-72"}>
    <DebugBar w={viewportWidth} />
    <div style={{width: `${scaleConfig.containerWidth}px`}}>{children}</div>
  </div>
}

const DebugBar: FC<{w: number}> = (props) => {
  const {scaleConfig, setScale} = usePlanner()
  const {messageHistory} = useContext(RealtimeContext)
  if (config.enableDebugMode) {
    const {mode, mousePos, floatingTask, area, slot} = usePlanner()
    return <div className={"fixed bottom-12 left-0 text-xs hidden lg:block rounded w-48 font-mono"}>
      <div>
        {messageHistory.map((message, index) => <div className={"whitespace-nowrap"} style={{opacity: (index)/messageHistory.length*1.5}} key={index}>{moment(new Date(message.timestamp)).format('hh:mm:ss')} - {message.event}</div>)}
      </div>
      <div className={"p-1 border-4 border-slate-500 bg-slate-800 text-white whitespace-nowrap"}>
        <div>Mode: {mode}</div>
        <div>Mouse: {mousePos?.x}, {mousePos?.y}</div>
        <div>Area: {area}</div>
        <div>Slot: {slot}</div>
        <div>Floating task: {floatingTask?.id}</div>
        <div>W: {props.w}</div>
        <div>Scale: {scaleConfig.scale}</div>
        <div>
          <input type="range" min={'10'} max={'200'} value={scaleConfig.scale * 100} onChange={(e) => setScale(Number(e.target.value) / 100)}/>
        </div>
      </div>
    </div>
  } else {
      return <></>
  }
}