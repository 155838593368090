import React, {FC} from "react";
import {ConfigInterface} from "../../config";

export const DesktopNavBarAppSwitcher: FC<{config: ConfigInterface}> = (props) => {
  const {planningUrl, crmUrl} = props.config
  const isPlanning = window.location.href.includes(props.config.planningUrl)
  const isCrm = window.location.href.includes(props.config.crmUrl)
  return <div className={'flex items-center'}>
    <a href={planningUrl} className={isPlanning ? 'text-sm font-medium px-2 py-1 rounded bg-white text-blue-800' : 'text-sm font-medium px-2 py-1 rounded'}>Planning & Werkbonnen</a>
    <a href={crmUrl} className={isCrm ? 'text-sm font-medium px-2 py-1 rounded bg-white text-blue-800' : 'text-sm font-medium px-2 py-1 rounded'}>Klanten & Projecten</a>
  </div>
}
export const MobileNavBarAppSwitcher: FC<{config: ConfigInterface}> = (props) => {
  const {planningUrl, crmUrl} = props.config
  const isPlanning = window.location.href.includes(props.config.planningUrl)
  const isCrm = window.location.href.includes(props.config.crmUrl)
  return <div className={'grid grid-cols-2 gap-2 mx-2'}>
    <a href={planningUrl} className={isPlanning ? 'text-sm font-medium px-2 py-1 rounded-t bg-white text-blue-950' : 'text-sm font-medium px-2 py-1 rounded-t'}>Planning & Werkbonnen</a>
    <a href={crmUrl} className={isCrm ? 'text-sm font-medium px-2 py-1 rounded-t bg-white text-blue-950' : 'text-sm font-medium px-2 py-1 rounded-t'}>Klanten & Projecten</a>
  </div>
}