
export const resolveConfig = (key: string, fallback: string): string => {
  // @ts-ignore
  return import.meta.env[key] ?? fallback
}
export interface ConfigInterface {
  apiBaseUrl: string,
  realtimeApiUrl: string,
  sentryDsn: string,
  enableDebugMode: boolean,
  planningUrl: string,
  googleApiKey: string,
  crmUrl: string,
  devMode: boolean,
  prodMode: boolean,
}