import React, {FC} from "react";
import * as fa from "@fortawesome/free-solid-svg-icons"
import {useNavigate} from "react-router-dom";
import {Employee} from "../api/dto";
import {useApi} from "../api/APIContext";
import {ModalFooter} from "@traas/lib/src/components/layout/ModalProvider";
import { Input } from "@traas/lib/src/components/form/Input";


export const DisableLoginModal: FC<{employee: Employee}> = (props) => {
  const {updateEmployee} = useApi()
  const confirm = async () => {
    await updateEmployee({...props.employee, email: null})
  }

  return <form>
    <p className={""}>
      <strong>Let op!</strong> Je staat op het punt om de login van <strong>{props.employee.firstName} {props.employee.lastName}</strong> uit te schakelen.
    </p>
    <ModalFooter icon={fa.faTimes} text={"Login uitschakelen"} onSubmit={confirm} />
  </form>
}
