import React, {FC, useState} from "react";
import * as fa from "@fortawesome/free-solid-svg-icons"
import {Holiday, Task, Team} from "../api/dto";
import {useApi} from "../api/APIContext";
import {ModalFooter} from "@traas/lib/src/components/layout/ModalProvider";
import {Toggle} from "@traas/lib/src/components/form/Toggle";
import {Input} from "@traas/lib/src/components/form/Input";


export const SendWorkordersModal: FC<{tasks:Task[]}> = (props) => {
  const {sendWorkorders} = useApi()
  const [addNote, setAddNote] = useState<string|null>(null)
  const confirm = async () => {
    await sendWorkorders(props.tasks, addNote ? addNote : undefined)
  }

  return <form>
    <p className={""}>
      <strong>Let op!</strong> Je staat op het punt om <strong>{props.tasks.length} {props.tasks.length === 1 ? 'werkbon' : 'werkbonnen'}</strong> te versturen.
      Deze zijn hierna zichtbaar voor de medewerkers van de desbetreffende teams.
    </p>
    <Toggle value={addNote !== null} onChange={(v) => setAddNote(v ? '' : null)} label={"Voeg een opmerking toe aan alle werkbonnen."} />
    {addNote !== null && <Input label={'Opmerking'} type={'textarea'} value={addNote} onChange={setAddNote} />}
    <ModalFooter icon={fa.faPaperPlane} text={"Verzenden"} onSubmit={confirm} />
  </form>
}
