import React, {FC, useContext, useEffect, useState} from "react";
import {useKeycloak} from "@react-keycloak/web";
import {useApi} from "../api/APIContext";
import {TaskSupplier} from "../api/dto";
import config from "../config";
import {useLiveLocations} from "./LiveLocationContext";
import {v4} from "uuid";

interface RealtimeContextType {
  onlineUsers: {sub: string, email: string|null, name: string, selectedDate?: Date}[]
  messageHistory: {event: string, timestamp: number}[]
  teamAvailabilityUpdatedAt: number
  dateSelected(date: Date): void
  isOpen: boolean
}
export const RealtimeContext = React.createContext<RealtimeContextType>({} as RealtimeContextType)

let connection: WebSocket|null = null

export const RealtimeContextProvider: FC<{children: React.ReactNode}> = ({children}) => {
  const {keycloak} = useKeycloak()
  const [latestMessage, setLatestMessage] = useState<string>()
  const [latestMessageAt, setLatestMessageAt] = useState(new Date().getTime())
  const [messageHistory, setMessageHistory] = useState<{event: string, timestamp: number}[]>([])
  const [isOpen, setIsOpen] = useState(false)
  const [sessionId, setSessionId] = useState<string|null>(null)
  const [onlineUsers, setOnlineUsers] = useState<{sessionId: string, sub: string, email: string|null, name: string, selectedDate?: Date}[]>([])
  const {onTaskCreated, onTaskUpdated, onTaskDeleted, onTasksUpdated, onTeamsUpdated, onSuppliersUpdated, onHolidaysUpdated, onEmployeesUpdated, onProjectsUpdated, onColorsUpdated, onNotesUpdated, onDailyRemarksUpdated, onQuantityTypesUpdated, onOrdersUpdated, onWorkordersUpdated, onWorkordersJobRan, onWorkordersJobWarning} = useApi()
  const {updateEmployeeLocation} = useLiveLocations()
  const [teamAvailabilityUpdatedAt, setTeamAvailabilityUpdatedAt] = useState(new Date().getTime())

  useEffect(() => {
    if (!connection) {
      const conn = new WebSocket(config.realtimeApiUrl)
      conn.onopen = () => {
        setIsOpen(true)
        conn.send(JSON.stringify({
            type: 'login',
            token: keycloak.token
        }))
      }
      conn.onmessage = (e) => {
        setLatestMessage(e.data)
        setLatestMessageAt(new Date().getTime())
      }
      connection = conn
    }
  }, [keycloak.token])

  useEffect(() => {
    const json = JSON.parse(latestMessage || '{}')
    if (json.type !== undefined) {
      setMessageHistory(old => {
        const newHistory = [...old, {event: json.type, timestamp: latestMessageAt}]
        if (newHistory.length > 10) {
          newHistory.shift()
        }
        return newHistory
      })
      switch (json.type) {
        case 'hello':
          setSessionId(json.sessionId)
          break
        case 'online_users':
          setOnlineUsers(json.users)
          break;
        case 'task_created':
          onTaskCreated({
            id: String(json.data.id),
            projectId: String(json.data.project_id),
            description: json.data.description,
            color: json.data.color,
            teamId: null,
            startAt: null,
            durationMinutes: null,
            suppliersStatus: 'todo',
            suppliers: [],
            quantityType: json.data.quantity_type,
            quantityAmount: json.data.quantity_amount,
            quantityThickness: json.data.quantity_thickness,
            thicknessUnit: json.data.thickness_unit,
            isInvoiced: json.data.is_invoiced,
            isVoided: json.data.is_voided,
            isSent: json.data.sent,
            shouldGenerateWorkorder: json.data.shouldGenerateWorkorder,
            workorderRemark: json.data.workorder_remark ?? null,
            projectRemark: json.data.project_remark ?? null,
            todos: json.data.todos ?? {},
          })
          break;
        case 'tasks_created':
          json.data.forEach((jsonItem: any) => {
            onTaskCreated({
              id: String(jsonItem.id),
              projectId: String(jsonItem.project_id),
              description: jsonItem.description,
              color: jsonItem.color,
              teamId: null,
              startAt: null,
              durationMinutes: null,
              suppliersStatus: 'todo',
              suppliers: [],
              quantityType: jsonItem.quantity_type,
              quantityAmount: jsonItem.quantity_amount,
              quantityThickness: json.data.quantity_thickness,
              thicknessUnit: json.data.thickness_unit,
              isInvoiced: jsonItem.is_invoiced,
              isVoided: jsonItem.is_voided,
              isSent: json.data.sent,
              shouldGenerateWorkorder: json.data.shouldGenerateWorkorder,
              workorderRemark: json.data.workorder_remark ?? null,
              projectRemark: json.data.project_remark ?? null,
              todos: json.data.todos ?? {},
            })
          })
          break;
        case 'task_updated':
          onTaskUpdated({
            id: String(json.data.id),
            projectId: String(json.data.project_id),
            description: json.data.description,
            color: json.data.color,
            teamId: json.data.team_uuid ? String(json.data.team_uuid) : null,
            startAt: json.data.start_at ? new Date(json.data.start_at) : null,
            durationMinutes: json.data.duration ? Number(json.data.duration) : null,
            suppliersStatus: json.data.suppliers_status ?? 'todo',
            suppliers: json.data.suppliers.map((taskSupplier: any) => {
              return new TaskSupplier(
                taskSupplier['id'],
                taskSupplier['supplier_id'],
                taskSupplier['description'],
                taskSupplier['status'],
                taskSupplier['is_complete'],
              )
            }) ?? [],
            quantityType: json.data.quantity_type,
            quantityAmount: json.data.quantity_amount,
            quantityThickness: json.data.quantity_thickness,
            thicknessUnit: json.data.thickness_unit,
            isInvoiced: json.data.is_invoiced,
            isVoided: json.data.is_voided,
            isSent: json.data.sent,
            shouldGenerateWorkorder: json.data.shouldGenerateWorkorder,
            workorderRemark: json.data.workorder_remark ?? null,
            todos: json.data.todos ?? {},
            projectRemark: json.data.project_remark ?? null,
          })
          break;
        case 'task_deleted':
          onTaskDeleted(String(json.data.id))
          break;
        case 'teams_updated':
          onTeamsUpdated()
          setTeamAvailabilityUpdatedAt(new Date().getTime())
          break;
        case 'tasks_updated':
          onTasksUpdated()
          break;
        case 'suppliers_updated':
          onSuppliersUpdated()
          break;
        case 'projects_updated':
          onProjectsUpdated()
          break;
        case 'colors_updated':
          onColorsUpdated()
          break;
        case 'quantity_types_updated':
          onQuantityTypesUpdated()
          break;
        case 'notes_updated':
          onNotesUpdated()
          break;
        case 'orders_updated':
          onOrdersUpdated()
          break;
        case 'daily_remarks_updated':
          onDailyRemarksUpdated()
          break;
        case 'workorders_updated':
          onWorkordersUpdated()
          break;
        case 'employees_updated':
          onEmployeesUpdated()
          setTeamAvailabilityUpdatedAt(new Date().getTime())
          break;
        case 'holidays_updated':
          onHolidaysUpdated()
          setTeamAvailabilityUpdatedAt(new Date().getTime())
          break;
        case 'employee_location':
          updateEmployeeLocation({
            id: v4(),
            employeeId: json.data.employee_id,
            lat: json.data.lat,
            lon: json.data.lon,
            createdAt: new Date(),
            updatedAt: new Date(),
          })
          break;
        case 'workorders_job_ran':
          onWorkordersJobRan(json.data.total)
          break;
        case 'workorders_job_warning':
          onWorkordersJobWarning(json.data.total)
          break;
        case 'broadcast:date_selected':
          const {data, session} = json.data as {data: { date: string }, session: string}
          if (session !== sessionId) {
            setOnlineUsers(old => {
              return old.map(u => {
                if (u.sessionId !== session) {
                  return u
                }
                return {
                  ...u,
                  selectedDate: new Date(data.date)
                }
              })
            })
          }
      }
    }
  }, [latestMessage, latestMessageAt, updateEmployeeLocation])

  return <RealtimeContext.Provider value={{
    messageHistory,
    onlineUsers: onlineUsers?.filter(u => u.sessionId !== sessionId).map(u => ({sub: u.sub, email: u.email, name: u.name, selectedDate: u.selectedDate})),
    teamAvailabilityUpdatedAt,
    dateSelected: (date) => {
      if (connection) {
        connection.send(JSON.stringify({
          type: 'date_selected',
          data: {
            date: date.toISOString(),
          },
        }))
      }
    },
    isOpen,
  }}>{children}</RealtimeContext.Provider>
}

export const useRealtimeUpdates = () => {
  const ctx = useContext(RealtimeContext)
  const [lastSentDate, setLastSentDate] = useState<string>()
  return {
    dateSelected: (date: Date, skipDebounce = false) => {
      if (!skipDebounce && lastSentDate === date.toISOString()) {
        return
      }
      if (! ctx.isOpen) {
        return
      }
      setLastSentDate(date.toISOString())
      ctx.dateSelected(date)
    },
  }
}