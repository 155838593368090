import React, {FC, useState} from "react";
import * as fa from "@fortawesome/free-solid-svg-icons"
import {useApi} from "../api/APIContext";
import {ModalFooter} from "@traas/lib/src/components/layout/ModalProvider";
import { Input } from "@traas/lib/src/components/form/Input";
import {ValidationError} from "../api/api";
import {MultiSelectCalendar} from "@traas/lib/src/components/form/MultiSelectCalendar";
import {PillSelect} from "@traas/lib/src/components/form/PillSelect";

export const AddGlobalHolidayModal: FC<{}> = () => {
  const {addHoliday} = useApi()
  const [name, setName] = useState("")
  const [type, setType] = useState<'company_wide_vacation'|'national_holiday'>("company_wide_vacation")
  const [dates, setDates] = React.useState<Date[]>([])
  const [errors, setErrors] = React.useState<string[]>([])

  const confirm = async () => {
    await addHoliday(dates, name, type)
      .catch(e => {
        if (e instanceof ValidationError) {
          setErrors(Object.values(e.errors).flat())
        }
        throw e
      })
  }

  return <form>
    <div className={"space-y-2"}>
      <PillSelect options={{'company_wide_vacation': 'Collectieve vrije dag', 'national_holiday': 'Feestdag'}}
                  value={type} onChange={(v) => setType(v as typeof type)}/>
      <MultiSelectCalendar taskDate={new Date()} selected={dates} setSelected={setDates}/>
      <Input type={"text"} label={"Omschrijving:"} placeholder={'Eerste kerstdag'} focus={true} value={name}
             onChange={setName}/>
      {errors.length > 0 && <div className={"text-red-600 text-sm"}>
        {errors.map((e, i) => <p key={i}>{e}</p>)}
      </div>}
      <ModalFooter icon={fa.faPlus} text={"Opslaan"} onSubmit={confirm}/>
    </div>
  </form>

}
